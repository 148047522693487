/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import Category from '../../smart/Category';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonFooter,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToolbar,
} from '@ionic/react';
import {Post} from '../../../models/post.model';
import {logoFacebook} from 'ionicons/icons';
import {CategoryModel} from '../../../models/Category.model';
import {CommunityPostEnum} from '../../../shared/types/enums/CommunityPostEnum';
import {ConfigPostType} from '../../../shared/types/ConfigPost.type';

const SelectCategory: React.FC<{onCategories: Function;categories: CategoryModel[]}> = ({onCategories, categories}) => {
  return (
    <IonGrid style={{height: '84%'}}>
      <IonRow style={{height: '100%'}}>
        <IonCol sizeMd="8" sizeXs="10" offsetMd="2" offsetXs="1" style={{height: '95%'}}>
          <Category
            height="100%"
            settedCategory={categories}
            seletedCategories={(categorie: CategoryModel[]): void => {
              onCategories(categorie);
            } } />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

interface PropsSelectPublic {
onCommunitySelected: Function;
community: CommunityPostEnum;
}
const SelectPublic: React.FC<PropsSelectPublic> = ({onCommunitySelected, community}) => {
  return (
    <IonGrid style={{height: '84%', overflow: 'auto'}}>
      <br/>
      <IonRow>
        <IonCol sizeMd="8" sizeXs="10" offsetMd="2" offsetXs="1">
          <IonLabel><h1>Seleccione una categoria</h1></IonLabel>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="8" sizeXs="10" offsetMd="2" offsetXs="1">
          <IonList lines="none">
            <IonRadioGroup
              onIonChange={(e): void => {
                onCommunitySelected(e.detail.value);
              }}
              value={community}
            >
              {Object.keys(CommunityPostEnum).map((value, index) =>
                <IonItem key={value}>
                  <IonLabel>{Object.values(CommunityPostEnum)[index]}</IonLabel>
                  <IonRadio slot="end" color="primary" value={value} />
                </IonItem>,
              )}
            </IonRadioGroup>
          </IonList>
        </IonCol>
        <IonCol sizeMd="8" sizeXs="10" offsetMd="2" offsetXs="1">
          {/* <SharedSocialMedia />*/}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SharedSocialMedia: React.FC = () => {
  return (
    <IonList lines="none">
      <IonListHeader style={{textAlign: 'center '}}>
        <IonLabel><h1>Compartir</h1></IonLabel>
      </IonListHeader>
      <IonItem>
        <IonCheckbox color="primary" checked slot="start" value='facebook' />
        <IonIcon icon={logoFacebook} color="primary" />
        <IonLabel>Facebook</IonLabel>
      </IonItem>
    </IonList>
  );
};

interface Props {
  onConfig?: Function;
  post: Post;
}

const ConfigPostPublish: React.FC<Props> = ({post, onConfig}) => {
  const contents = [SelectCategory, SelectPublic];
  const [count, setCount] = useState<number>(0);
  const [animate, setAnimated] = useState('slideInRight');
  const [infoConfig, setInfoConfig] =
      useState<ConfigPostType>(
          {
            categories: post.categories ? post.categories : [],
            community: post.community ? post.community : CommunityPostEnum.ALL,
            mode: post.mode,
          },
      );
  return (
    <>
      {[contents[count]].map((Component) => (
        <div className={`animated ${animate}`} key={Component.name} style={{height: '83%'}}>
          <Component
            onCategories={(cat: CategoryModel[]): void => {
              if (JSON.stringify(infoConfig.categories) !== JSON.stringify(cat)) {
                setInfoConfig({...infoConfig, categories: cat});
              }
            }}
            onCommunitySelected={(communityPost: CommunityPostEnum): void => {
              setInfoConfig({...infoConfig, community: communityPost});
            }}
            categories={infoConfig.categories}
            community={infoConfig.community}
          />
        </div>),
      )}
      <IonFooter>
        <IonGrid>
          <IonRow>
            <IonCol sizeMd="8" sizeXs="10" offsetMd="2" offsetXs="1" style={{height: '95%'}}>
              <IonToolbar>
                <IonButtons slot="start">
                  <IonButton
                    disabled={count <= 0}
                    color="primary"
                    onClick={(): void =>{
                      setCount(count - 1);
                      setAnimated('slideInLeft');
                    }}>
                    <IonLabel>Atras</IonLabel>
                  </IonButton>
                </IonButtons>
                <IonButtons slot="end">
                  {count +1 === contents.length ?
                      <IonButton
                        color="primary"
                        onClick={(): void => {
                          if (onConfig)onConfig(infoConfig);
                        }}
                        disabled={(infoConfig.categories.length === 0) }
                      >
                        <IonLabel>Finalizar</IonLabel>
                      </IonButton> :
                      <IonButton
                        color="primary"
                        disabled={false}
                        onClick={(): void => {
                          setCount(count + 1);
                          setAnimated('slideInRight');
                        }}>
                        <IonLabel>Siguiente</IonLabel>
                      </IonButton>
                  }
                </IonButtons>
              </IonToolbar>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  );
};

export default ConfigPostPublish;
