/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/explicit-function-return-type */
import {UserProfileType} from '../../shared/types/UserProfile.type';
import {useEffect, useState} from 'react';
import {userProfileService} from '../../services/userProfile.service';
import {Observable, Subscription} from 'rxjs';
import {onEmit} from './postFacade.hook';
import {userProfileQuery} from '../../query/userProfile.query';
import {ID} from '@datorama/akita';

interface UserProfileStateFacade {
  usrPrf: UserProfileType[];
  active: UserProfileType | undefined;
}

export function useUserProfileFacade(loadBd?: boolean): [
  {
    state: UserProfileStateFacade; update: Function; loadAll: Function; setActive: Function; setLoaded: Function;
    removeAll: Function; listUsers: Function; removeActive: Function;
  }
] {
  const [profileState, setProfileState] = useState<UserProfileStateFacade>({usrPrf: [], active: undefined});

  const updateProfile = (profile: UserProfileType): void => userProfileService.updateProfile(profile);
  const loadAll = (): void => {
    userProfileService.getProfile();
  };
  const loadLocal = (): Observable<UserProfileType[]> => userProfileQuery.selectAll();
  const setActive = (id: ID): void => userProfileService.setActive(id);
  const listProfiles = (): void => userProfileService.getListProfile();
  const setLoadedProfile = (profile: UserProfileType): void => userProfileService.setLoadedProfile(profile);
  const removeAll = (): void => userProfileService.removeAll();
  const removeActive = (): void => userProfileService.removeActive();

  useEffect(() => {
    const userProfileSubscribed: Subscription[] = [
      onEmit<UserProfileType[]>(userProfileQuery.userProfile$, (profiles) => {
        if (JSON.stringify(profileState) !== JSON.stringify(profiles)) {
          setProfileState((profile)=> ({...profile, usrPrf: profiles}));
        }
      }),
      onEmit<UserProfileType>(userProfileQuery.activeUserProfile$, (active)=>{
        setProfileState((state) => ({...state, active}));
      }),
    ];

    if (loadBd) userProfileService.getProfile();

    return (): void => {
      userProfileSubscribed.map((value) => value.unsubscribe());
    };
    // eslint-disable-next-line
  }, []);

  return [
    {state: profileState, update: updateProfile, loadAll, setActive, setLoaded: setLoadedProfile,
      removeAll, listUsers: listProfiles, removeActive,
    },
  ];
}
