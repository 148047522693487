/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {useState} from 'react';
import {IonAvatar, IonItem, IonLabel, IonList, IonIcon, IonRadioGroup} from '@ionic/react';
import {UserProfileType} from '../../../shared/types/UserProfile.type';
import {
  ANIMATION_FADE_IN_DOWN,
  ANIMATION_FADE_IN_RIGHT, ANIMATION_FADE_IN_UP,
} from '../../../shared/ConstantDataStructure';
import '../../../styles/UserList.scss';
import {more} from 'ionicons/icons';
import GenericPopOver from '../../dummie/PopOver/GenericPopOver';
import {whatever} from '../../../shared/customType';
import {roleUser} from '../../../shared/types/enums/prfEnum';
import {createRadioEnum} from '../../../shared/EnumUtil';

interface Prop {
  userList: UserProfileType[];
  updateRole: Function;
}

const UserList: React.FC<Prop> = ({userList, updateRole}) => {
  const [popEvent, setPopEvent] = useState();
  const [popOption, setPopOption] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<string>('');
  const [popRadio, setPopRadio] = useState<boolean>(true);
  const [outAnimation, setOutAnimation] = useState<boolean>(false);
  const [activeUser, setActiveuser] = useState<UserProfileType>();
  return (
    <>
      <IonList>
        {userList.map((user: UserProfileType, index) =>(
          <IonItem key={index} lines="none">
            <IonAvatar
              className={`${ANIMATION_FADE_IN_RIGHT} avatarStyle`}
            >
              <img src="assets/image/avatar-icon.jpg" width="50" height="50" alt="profile" />
            </IonAvatar>
            <div className="contentUser">
              <IonLabel className={`userLabel ${ANIMATION_FADE_IN_DOWN}`}>{user.name}</IonLabel>
              <IonLabel className={`userSubLabel ${ANIMATION_FADE_IN_UP}`}>
                {roleUser[user?.role as keyof typeof roleUser]}
              </IonLabel>
            </div>
            <IonItem
              lines="none"
              className={`buttonMore ${ANIMATION_FADE_IN_RIGHT}`}
              slot="end"
              button
              onClick={(e: whatever) => {
                e.persist();
                setPopEvent(e);
                setActiveuser(user);
                setRadioValue(user.role as string);
                setPopOption(true);
                setPopRadio(true);
              }}>
              <IonIcon icon={more} />
            </IonItem>
          </IonItem>
        ))}
      </IonList>
      {popRadio ?
        <GenericPopOver
          open={popOption}
          onClose={() => setPopOption(false)}
          event={popEvent}
          willClose={outAnimation}>
          <IonList>
            <IonRadioGroup onIonChange={(event: whatever) => {
              if (event.detail.value) {
                setRadioValue(event.detail.value);
                setOutAnimation(true);
                updateRole({...activeUser, role: event.detail.value});
                setTimeout(() => {
                  setPopRadio(false);
                  setOutAnimation(false);
                }, 450);
              }
            }}
            value={radioValue}
            >
              {createRadioEnum(roleUser)}
            </IonRadioGroup>
          </IonList>
        </GenericPopOver> :
        null}
    </>
  );
};

export default UserList;
