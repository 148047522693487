import {useEffect, useState} from 'react';
import {PreferenceType} from '../../shared/types/preference.type';
import {preferenceService} from '../../services/preference.service';
import {Subscription} from 'rxjs';
import {onEmit} from './postFacade.hook';
import {preferenceQuery} from '../../query/preference.query';

interface PreferenceFacade {
    prefs: PreferenceType[];
    active: PreferenceType | undefined;
}

export function usePreferences(): [{preferenceState: PreferenceFacade; getPreference: Function}] {
  const [preferenceState, setPreferenceState] = useState<PreferenceFacade>({prefs: [], active: undefined});

  const getPreference = (userId: number): void => preferenceService.getPreference(userId);

  useEffect(() => {
    const preferenceSubscribed: Subscription[] = [
      onEmit<PreferenceType[]>(preferenceQuery.preferences$, (preferences) => {
        if (JSON.stringify(preferenceState) !== JSON.stringify(preferences)) {
          setPreferenceState((preference) => ({...preference, prefs: preferences}));
        }
      }),
      onEmit<PreferenceType>(preferenceQuery.activePreference$, (activeState) => {
        setPreferenceState((prevState) =>({...prevState, active: activeState}));
      }),
    ];

    return (): void => {
      preferenceSubscribed.map((value) => value.unsubscribe());
    };
    // eslint-disable-next-line
  }, []);

  return [{preferenceState, getPreference}];
}
