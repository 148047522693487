import {ConfigurationState, configurationStore, ConfigurationStore} from '../stores/configuration.store';
import {Http} from '../shared/http.class';
import {API_CMS_URL} from '../shared/ConstantUrl';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {GenericResponseType, ResponseError} from '../shared/types/genericResponse.type';

type ObsConfig = Observable<GenericResponseType<ConfigurationState>>

export class ConfigurationService {
  private http: Http;

  constructor(private configurationStore: ConfigurationStore) {
    this.http = Http.httpGetInstance();
  }

  public updateConfiguration(configuration: ConfigurationState): void {
    this.configurationStore.update({...configuration, change: Math.random()});
  }

  public getDomain(): ObsConfig {
    this.http.baseUrl = API_CMS_URL;
    return this.http.get$(`domain`).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsConfig),
        tap((x) => {
          if (x.data?.id) this.updateConfiguration(x.data);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.configurationStore, err)),
    );
  }

  public saveDomainConfig(config: ConfigurationState): ObsConfig {
    this.http.baseUrl = API_CMS_URL;
    return this.http.post$(`domain`, config).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsConfig),
        tap((x) => {
          if (x.data?.id) this.updateConfiguration(x.data);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.configurationStore, err)),
    );
  }

  public updateDomain(config: ConfigurationState): ObsConfig {
    this.http.baseUrl = API_CMS_URL;
    this.updateConfiguration(config);
    return this.http.put$('domain', config, config.id).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsConfig),
        tap((x) => {
          if (x.data?.id) {
            this.updateConfiguration(x.data);
          }
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.configurationStore, err)),
    );
  }
}

export const configurationService = new ConfigurationService(configurationStore);
