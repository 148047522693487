export const CATEGORY_TITLE = 'Categorias';
export const FILTER_FACEBOOK_PAGE_NAME = 'Yenando el pañuelo de mocos';
export const INPUT_TYPE_FILE = 'image/*';
export const ERROR_HTTP = 'Se produjo un problema. Estamos trabajando para solucionarlo lo antes posible.';
export const AUTHG_SCOPE = ' https://www.googleapis.com/auth/youtube.readonly https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtubepartner https://www.googleapis.com/auth/youtubepartner-channel-audit https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/youtube.force-ssl';
export const AUTHG_PROMPT = 'consent';
export const AUTHG_ACCESS_TYPE = 'offline';
export const AUTHG_INCLUDE_GRANTED_SCOPES = 'true';
export const AUTHG_STATE = 'state_parameter_passthrough_value';
export const AUTHG_RESPONSE_TYPE = 'code';
export const AUTHG_CLIENT_ID = '149740006499-s52kgae2ubt5l5rjkqntguha066jj1n1.apps.googleusercontent.com';
export const CATEGORY_NOT_DELETED = 'Una o mas categorias no se pudieron eliminar.';
