/* eslint-disable */
import {IonContent, IonGrid, IonPage, IonRow, IonSpinner} from '@ionic/react';
import React, {useEffect, useState} from 'react';
import {usePostFacade} from '../../hooks/facade/postFacade.hook';
import PostPresentation from '../dummie/post/postPresentation';
import {RouteComponentProps} from 'react-router';
import {Post} from '../../models/post.model';
import {ID} from '@datorama/akita';
import {postService} from '../../services/post.service';
import {CategoryModel} from '../../models/Category.model';
import Loader from '../dummie/Loader/loader';
import SlidePost from '../dummie/SlidesPost/SlidePost';
import InfiniteScroll from "react-infinite-scroll-component";
import {useGlobalFacade} from "../../hooks/facade/globalFacade.hook";
import MinimalismCategory from "../dummie/Category/MinimalismCategory";

const Home: React.FC<RouteComponentProps> = ({history}) => {
  const [{state: {posts}, loadAll, loadingStatePost,resetStore}] = usePostFacade();
  const [categorySelected, setCategorySelected] = useState<CategoryModel | null>(null);
  const [{hasMorePost}] = useGlobalFacade();

  useEffect(() => {
    if (history.location.pathname === '/') {
      resetStore();
      loadAll();
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const filterByCategory = (): Post[] => {
    if (categorySelected) {
      return posts.filter((post) => {
        if (post.categories) {
          return post.categories.filter((cat) => cat.id === categorySelected.id).length;
        } else return post;
      });
    }
    return posts;
  };

  const sortPostLike = (postToSort: Post[]): Post[] => {
    return postToSort.filter((post) => post.like && post.like > 0 ).sort((a, b) => {
      if (a && a.like && b && b.like) return b.like - a.like;
      return -1;
    } );
  };

  const listPostByCategory = filterByCategory().sort((a, b) => {
    return new Date(b.creationDate as Date).getTime() - new Date(a.creationDate as Date).getTime();
  });

  const researchPost = ():void  => {
      if(posts.length > 0){
        loadAll(posts.length)
      }
  };

  return (
    <IonPage>
      <Loader isLoader={loadingStatePost} history={history}/>
      <IonContent className="ion-padding">
        <div id="scrollableDiv" style={{height: '100%', overflow: 'auto'}}>
          <InfiniteScroll
              dataLength={posts.length}
              next={researchPost}
              hasMore={hasMorePost}
              loader={<IonSpinner style={{display: 'block',margin: 'auto'}} name="circles" />}
              scrollableTarget="scrollableDiv"
          >
              {sortPostLike(posts).length > 2 ?
                  <SlidePost posts={sortPostLike(posts).slice(0, 10)} titleSlide="Destacados"/> : null}
              <MinimalismCategory returnedCategory={(category: CategoryModel): void => setCategorySelected(category)} />
              <PostPresentation
                posts={listPostByCategory}
                onSelectPost={(post: Post): void => postService.setActive(post.id as ID)}
                fetchMoreData={() => {
                  console.log('hacer lo que se me de la gana despues de llegar al final');
                }}
              />
          </InfiniteScroll>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
