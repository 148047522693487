import {categoryStore, CategoryStore} from '../stores/category.store';
import {CategoryModel} from '../models/Category.model';
import {Http} from '../shared/http.class';
import {API_CMS_URL, CATEGORIES} from '../shared/ConstantUrl';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {GenericResponseType} from '../shared/types/genericResponse.type';
import {Observable} from 'rxjs';

type ObsCategoryArray = Observable<GenericResponseType<CategoryModel[]>>
type ObsCategory = Observable<GenericResponseType<CategoryModel>>

export class CategoryService {
  private http: Http;
  constructor(private categoryStore: CategoryStore) {
    this.http =Http.httpGetInstance();
  }

  getAll(): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.get$(CATEGORIES).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsCategoryArray ),
        tap( (x) => this.categoryStore.set(x.data)),
        catchError((err) => this.http.addErrorStore(this.categoryStore, err)),
    ).subscribe();
  }

  add(category: CategoryModel): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.post$(CATEGORIES, category).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsCategory),
        tap((x) => this.categoryStore.add(x.data)),
        catchError((err) => this.http.addErrorStore(this.categoryStore, err)),
    ).subscribe();
  }

  remove(id: number): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.delete$(CATEGORIES, id).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsCategoryArray),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tap((x) => {
          this.categoryStore.remove(id);
        }),
        catchError((err) => this.http.addErrorStore(this.categoryStore, err)),
    ).subscribe();
  }
}

export const categoryService = new CategoryService(categoryStore);

