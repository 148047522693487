import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {CategoryModel} from '../models/Category.model';

export type CategoryState = EntityState<CategoryModel>

@StoreConfig({name: 'categories'})
export class CategoryStore extends EntityStore<CategoryState, CategoryModel> {
  // eslint-disable-next-line
  constructor() {
    super();
  }
}

export const categoryStore = new CategoryStore();
