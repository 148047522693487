/* eslint-disable @typescript-eslint/no-useless-constructor */
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Post} from '../models/post.model';


export type PostSate = EntityState<Post>

@StoreConfig({name: 'post'})
export class PostStore extends EntityStore<PostSate, Post> {
  constructor() {
    super();
  }
}

export const postStore = new PostStore();
