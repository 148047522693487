export const optionsEditor = [
  'inline',
  'blockType',
  'list',
  'textAlign',
  'colorPicker',
  'link',
  'embedded',
  'emoji',
  'history'];

export const blockType = {
  inDropdown: false,
  options: ['Normal', 'H2', 'H4', 'H6', 'Blockquote'],
};

export const fontFamily = {
  options: ['Arial', 'Times New Roman'],
};

export const inline = {
  options: ['bold', 'italic', 'underline', 'strikethrough'],
};

export const embedded= {
  className: undefined,
  component: true,
  popupClassName: undefined,
  embedCallback: undefined,
  defaultSize: {
    height: 'auto',
    width: 'auto',
  },
};
