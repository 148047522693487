import {Http} from '../shared/http.class';
import {preferenceStore, PreferenceStore} from '../stores/preference.store';
import {API_AUTH_URL, PREFERENCES} from '../shared/ConstantUrl';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {getObject$, setObject} from '../shared/Storage';
import {PROFILE} from '../shared/ConstantDataStructure';
import {UserProfileType} from '../shared/types/UserProfile.type';

export class PreferenceService {
    private http: Http;

    constructor(private preferenceStore: PreferenceStore) {
      this.http = Http.httpGetInstance();
    }

    getPreference(userId: number): void {
      this.http.baseUrl = API_AUTH_URL;
      this.http.get$(`${PREFERENCES}/${userId}`).pipe(
          mergeMap((value) => this.http.validateResponseOfRequest(value)),
          tap((x) => {
            this.preferenceStore.set([x]);
            this.preferenceStore.setActive(x.id);
            getObject$<UserProfileType>(PROFILE).subscribe((value) => {
              value = {...value, name: x.displayName, image: x?.image};
              setObject(value, PROFILE);
            });
          }),
          catchError((err) => this.http.addErrorStore(this.preferenceStore, err)),
      ).subscribe();
    }
}

export const preferenceService = new PreferenceService(preferenceStore);
