import {GlobalState, globalStore, GlobalStore} from '../stores/global.store';
import {ResponseError} from '../shared/types/genericResponse.type';

class GlobalService {
  constructor(private globalStore: GlobalStore) {}

  updateError(error: ResponseError): void {
    this.globalStore.update((state) => {
      return {...state, errorHttp: error};
    });
  }

  update({hasMorePost}: Partial<GlobalState>): void {
    this.globalStore.update((state) => {
      return {...state, hasMorePost};
    });
  }

  reset(): void {
    this.globalStore.reset();
  }
}

export const globalService = new GlobalService(globalStore);
