import React from 'react';
import {IonFab, IonFabButton, IonFabList, IonIcon} from '@ionic/react';
import {cloudUpload, logoYoutube} from 'ionicons/icons';
import InputFile from './InputFile/InputFile';
import {Media} from '../../../shared/types/media.type';

interface Props {
  onMedia: Function;
  loadYoutube: Function;
}

const UploadMedia: React.FC<Props> = ({onMedia, loadYoutube}) => {
  return (
    <>
      <IonFab edge>
        <IonFabButton color="second">
          <IonIcon icon={cloudUpload} />
        </IonFabButton>
        <IonFabList side="end">
          <IonFabButton>
            <InputFile multiple={false} onFile={(e: Media[]): void => {
              onMedia(e[0]);
            }}/>
          </IonFabButton>
          <IonFabButton onClick={(): void => loadYoutube(true)}>
            <IonIcon icon={logoYoutube}/>
          </IonFabButton>
        </IonFabList>
      </IonFab>
    </>
  );
};
export default UploadMedia;
