/* eslint-disable @typescript-eslint/explicit-function-return-type,no-console,@typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {ToolbarProps} from '../../../models/toolbarProps';
import {IonAvatar, IonButton, IonButtons, IonThumbnail, IonToolbar} from '@ionic/react';
import {AlertModal} from '../Modal/AlertModal';
import Login from '../../smart/Login';
import {clear$, getObject$, setObject} from '../../../shared/Storage';
import {ANIMATION_FADE_IN_RIGHT, PROFILE, TOKEN} from '../../../shared/ConstantDataStructure';
import {TokenModel} from '../../../models/token.model';
import GenericPopOver from '../PopOver/GenericPopOver';
import LoggedMenu from '../LoggedMenu/LoggedMenu';
import Profle from '../Profile/Profile';
import {Link} from 'react-router-dom';
import {useUserProfileFacade} from '../../../hooks/facade/userProfileFacade.hook';
import {prfEnum} from '../../../shared/types/enums/prfEnum';
import {UserProfileType} from '../../../shared/types/UserProfile.type';
import {whatever} from '../../../shared/customType';
import {useConfigurationFacade} from '../../../hooks/facade/configurationFacade.hook';
import {useCategoryFacade} from '../../../hooks/facade/categoryFacade.hook';
import {usePreferences} from '../../../hooks/facade/PreferenceFacade.hook';
import '../../../styles/CmsToolbar.scss';
import {URL_IMAGE_SMALL} from '../../../shared/ConstantUrl';


const CmsToolbar: React.FC<ToolbarProps> = ({children}) => {
  const [showModal, setShowModal] = useState(false);
  const [token, setToken] = useState<TokenModel>();
  const [userMenu, setUserMenu] = useState(false);
  const [propEvent, setPropEvent] = useState();
  const [showProfile, setShowProfile] = useState(false);
  const [prof, setProf] = useState<UserProfileType>();
  const [
    {state: {usrPrf, active}, update, loadAll, setActive, removeAll, removeActive},
  ] = useUserProfileFacade();
  const [showPopOver, setShowPopOver] = useState<boolean>(true);
  const [{logo, idDomain, getDomain}] = useConfigurationFacade();
  const [{catState: {categories}, getAll}] = useCategoryFacade();
  const [{preferenceState: {prefs, active: activePref}, getPreference}] = usePreferences();

  useEffect(() => {
    getObject$<TokenModel>(TOKEN).subscribe((value) => {
      if (!token && value && JSON.stringify(token) !== JSON.stringify(value)) {
        setToken(value as TokenModel);
      }
    });
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (usrPrf.length === 0 && token) {
      getObject$(PROFILE).subscribe((value) => {
        if (!value) {
          loadAll();
        }
      });
    }
    // eslint-disable-next-line
  }, [token, usrPrf]);

  useEffect(()=> {
    getObject$(PROFILE).subscribe((profile) => {
      if (!profile && active && token) {
        const newProf = {
          id: active.id,
          role: token.Administrator ? prfEnum.ADM : active.role ? active.role : prfEnum.USR,
          name: active.name,
          user: active.user,
        };
        setObject(newProf, PROFILE);

        getPreference(active.user);
        setProf(newProf);
        // if (!active.role) update(newProf);
        setActive(newProf.id);
      }
    });
    // eslint-disable-next-line
  }, [token, active]);


  useEffect(() => {
    if (!prof) {
      getObject$(PROFILE).subscribe((value) => {
        if (value) setProf(value as UserProfileType);
      });
    }
    // eslint-disable-next-line
  }, [prof]);

  useEffect(() => {
    if (idDomain && !categories.length) {
      getAll();
    }
    // eslint-disable-next-line
  }, [idDomain]);


  useEffect(() => {
    getObject$<UserProfileType>(PROFILE).subscribe((value) => {
      if (value?.image && !activePref) {
        setProf(value);
        update({...value, name: value.name});
      } else if (prof && prefs && activePref && !activePref.image) {
        setProf(
            {...prof,
              image: activePref?.imageUrl ?
                  activePref.imageUrl : activePref?.image, name: activePref?.displayName as string,
            });
        update({...prof, name: activePref?.displayName as string});
      }
    });
    // eslint-disable-next-line
  }, [prefs, activePref]);

  useEffect(()=> {
    if (activePref) {
      setProf({...prof, name: activePref.displayName, image: activePref?.imageUrl ?? activePref.image});
    }
    // eslint-disable-next-line
  }, [activePref]);

  const logOut = () => {
    clear$().subscribe((value) => {
      localStorage.clear();
      setToken(undefined);
      setUserMenu(false);
      setProf(undefined);
      removeAll();
      if (active && active.id) removeActive(active.id);
      if (window.location.pathname !== '/') window.location.href = '/';
    });
    // window.location.reload();
  };

  const recivedProfileMessage = (event: whatever) => {
    if (event.data.profileMessage) {
      getObject$<UserProfileType>(PROFILE).subscribe((value) => {
        getPreference(value?.user);
      });
    }
  };

  // eslint-disable-next-line
  useEffect(() => window.addEventListener('message', recivedProfileMessage, false), []);

  const displayProfile = () => {
    setShowProfile(true);
    setUserMenu(false);
  };

  useEffect(() => {
    if (!active) {
      getObject$<UserProfileType>(PROFILE).subscribe((value) => {
        if (value) setActive(value.id);
      });
    }
    // eslint-disable-next-line
  }, [usrPrf]);

  return (
    <>
      <IonToolbar>
        <div style={{display: 'flex'}}>
          <IonButtons slot="primary">
            <Link to="/" >
              <IonThumbnail>
                <img
                  style={{marginLeft: '10px', width: 'auto', maxWidth: 'none'}}
                  src={logo.includes('logo-preloader') || logo.includes('base64') ? logo :URL_IMAGE_SMALL+logo}
                  alt="logo"
                />
              </IonThumbnail>
            </Link>
          </IonButtons>
          <div style={{width: '100%', display: 'flex'}}>{children}</div>
          <IonButtons slot="end">
            {token && prof ?
              <IonAvatar
                className={ANIMATION_FADE_IN_RIGHT}
                style={{height: '50px', width: '50px', marginRight: '8px'}}
                onClick={(e) => {
                  if (!active) loadAll();
                  e.persist();
                  setPropEvent(e);
                  setUserMenu(true);
                }}>
                <img src={prof?.image ? URL_IMAGE_SMALL + prof.image : 'assets/image/avatar-icon.jpg'} alt="profile" />
              </IonAvatar> :
              <IonButton
                className={ANIMATION_FADE_IN_RIGHT}
                color={'primary'}
                onClick={()=> setShowModal(true)}>Sign In</IonButton>
            }
          </IonButtons>
        </div>
      </IonToolbar>
      <AlertModal
        isOpen={showModal}
        dismiss={(value: boolean)=> setShowModal(value)}
        ModalContent={<Login setLogin={(value: TokenModel) => {
          if (value) {
            setShowModal(false);
            setToken(value);
          }
        }} />}
        title={'Login'}/>
      {showPopOver ?
        <GenericPopOver
          open={userMenu}
          onClose={() => {
            setUserMenu(false);
            setShowPopOver(true);
          }}
          event={propEvent}
        >
          <div className="titleMenuProfile">{prof?.name}</div>
          <LoggedMenu
            activeUser={active as UserProfileType}
            autoClose={(value: whatever)=>setShowPopOver(!value)}
            logOut={logOut}
            settingBtn={() => {
              setUserMenu(false);
            }}
            showProfile={displayProfile}/>
        </GenericPopOver> :
        null}
      <AlertModal
        isOpen={showProfile}
        dismiss={(value: boolean) => setShowProfile(value)}
        ModalContent={<Profle />}
        title={'Setting'}/>
    </>
  );
};

export default CmsToolbar;
