import React, {useState} from 'react';
import {IonLabel, IonSegment, IonSegmentButton} from '@ionic/react';
import {whatever} from '../../../shared/customType';
import Search from './search';
import VideoByUrl from './videoByURL';
import UploadVideoYoutube from './UploadVideoYoutube/UploadVideoYoutube';

interface Props {
  selectedVideo: Function;
}

const Principal: React.FC<Props> = ({selectedVideo}) => {
  const [selOption, setSelOption] = useState('opt1');
  const handleOption = (value: string): void => {
    setSelOption(value);
  };
  return (
    <>
      <IonSegment onIonChange={(e: whatever): void => handleOption(e.target.value)}>
        <IonSegmentButton value="opt1" checked={selOption === 'opt1'}>
          <IonLabel>Buscar Videos</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="opt2" checked={selOption === 'opt2'}>
          <IonLabel>Agregar URL</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="opt3" checked={selOption === 'opt3'}>
          <IonLabel>Subir Video</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      {selOption === 'opt1' ? <Search selected={selectedVideo} /> : null }
      {selOption === 'opt2' ? <VideoByUrl selected={selectedVideo} /> : null }
      {selOption === 'opt3' ? <UploadVideoYoutube selected={selectedVideo} /> :null}
    </>
  );
};

export default Principal;
