import React, {useState} from 'react';
import {IonAvatar, IonButton, IonCol, IonGrid, IonItem, IonLabel, IonList, IonRow} from '@ionic/react';

import './Appeareance.css';
import InputFile from '../UploadMedia/InputFile/InputFile';
import {ConfigurationState} from '../../../stores/configuration.store';
import {Media} from '../../../shared/types/media.type';
import {URL_IMAGE_SMALL} from '../../../shared/ConstantUrl';

interface Props {
    updateConfig: Function;
    config: ConfigurationState;
}

const ConfigAppearance: React.FC<Props> = ({config, updateConfig}) => {
  const [configuration, setConfiguration] = useState(config);

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="10" offset="1">
          <IonList>
            <IonItem button>
              <IonAvatar slot="end" className="logo">
                <img
                  alt="logo"
                  src={configuration.logo.includes('assets') || configuration.logo.includes('base64') ?
                      configuration.logo : URL_IMAGE_SMALL + configuration.logo
                  }
                />
              </IonAvatar>
              <IonLabel>
                <h2>Logo de la pagina</h2>
              </IonLabel>
              <InputFile id="logo" onFile={(image: Media[]): void =>setConfiguration((prevState) => {
                return {...prevState, logo: image[0].base64};
              })} multiple={false}/>
            </IonItem>
            <IonItem button>
              <IonAvatar slot="end" className="favicon">
                <img
                  alt="favicon"
                  src={`${!configuration.favicon.includes('assets') ? URL_IMAGE_SMALL : ''}${configuration.favicon}`}
                />
              </IonAvatar>
              <IonLabel>
                <h2>Favicon de la pagina</h2>
              </IonLabel>
              <InputFile id="favicon" onFile={(image: Media[]): void => setConfiguration((prevState) => {
                return {...prevState, favicon: image[0].base64};
              })} multiple={false}/>
            </IonItem>
            <br />
          </IonList>
          <br />
          <IonButton expand="block" onClick={(): void => updateConfig(configuration)}>Guardar</IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ConfigAppearance;
