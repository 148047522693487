/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import {IonIcon, IonItem, IonLabel, IonList} from '@ionic/react';
import {LoggedMenuModel} from '../../../models/loggedMenu.model';
import {Link} from 'react-router-dom';
import './loggedMenu.css';
import {listBox, person, logOut as logout, hammer, paper} from 'ionicons/icons';

const LoggedMenu: React.FC<LoggedMenuModel> = ({settingBtn, showProfile, logOut, autoClose, activeUser}) => {
  return (
    <IonList lines="none">
      <>
        <Link to="/new-post/">
          <IonItem button onClick={()=> autoClose ? autoClose(true) : null}>
            <IonIcon slot="end" icon={listBox} />
            <IonLabel>Crear Post</IonLabel>
          </IonItem>
        </Link>
        <Link to="/profile/">
          <IonItem button onClick={() => {
            settingBtn();
            if (autoClose) autoClose(true);
          }}>
            <IonIcon slot="end" icon={paper}/>
            <IonLabel>Posts</IonLabel>
          </IonItem>
        </Link>
      </>
      <IonItem button onClick={()=>{
        showProfile();
        if (autoClose) autoClose(true);
      }}>
        <IonIcon slot="end" icon={person} />
        <IonLabel>Perfil</IonLabel>
      </IonItem>
      {activeUser?.role === 'ADMIN' ?
        <Link to="/settings/">
          <IonItem button onClick={() => {
            if (autoClose) autoClose(true);
          }}>
            <IonIcon icon={hammer} slot="end" />
            <IonLabel>Configuracion</IonLabel>
          </IonItem>
        </Link> :
        null}
      <IonItem button onClick={()=>{
        logOut();
        if (autoClose) autoClose(true);
      }}>
        <IonIcon slot="end" icon={logout} />
        <IonLabel>Log Out</IonLabel>
      </IonItem>
    </IonList>
  );
};

export default LoggedMenu;
