import React from 'react';
import {CategoryModel} from '../../../models/Category.model';
import {IonCheckbox, IonItem, IonLabel, IonList} from '@ionic/react';

interface Props {
  categories: CategoryModel[];
  addCategory: Function;
  removeCategory: Function;
}

const ListCategory: React.FC<Props> = ({categories, addCategory, removeCategory}) => {
  return (
    <>
      <IonList>
        {categories.map((value, index) => (
          <IonItem key={index} lines="none" color={'gray'}>
            <IonCheckbox
              slot={'end'}
              color="primary"
              checked={value.isChecked}
              onClick={(): void => {
                value.isChecked = !value.isChecked;
                categories[index] = {...categories[index], isChecked: value.isChecked};
                value.isChecked ? addCategory(value) : removeCategory(value);
              }}
            />
            <IonLabel>
              {value.name}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default ListCategory;
