import {Query} from '@datorama/akita';
import {GlobalState, globalStore, GlobalStore} from '../stores/global.store';
import {Observable} from 'rxjs';
import {ResponseError} from '../shared/types/genericResponse.type';

export class GlobalQuery extends Query<GlobalState> {
 public errorHttp$: Observable<ResponseError | null> = this.select('errorHttp');
 public tokenYoutubeActive$: Observable<string> = this.select('tokenYoutubeActive');
 public hasMorePost$: Observable<boolean> = this.select('hasMorePost');

 constructor(private readonly globalStore: GlobalStore) {
   super(globalStore);
 }
}

export const globalQuery = new GlobalQuery(globalStore);
