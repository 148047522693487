import React, {useEffect, useState} from 'react';
import {
  IonAvatar, IonButton,
  IonCard, IonCardContent, IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonItem, IonLabel, IonList,
  IonPage,
  IonRow, IonTextarea,
} from '@ionic/react';
import {usePostFacade} from '../../hooks/facade/postFacade.hook';
import {CommentType} from '../../shared/types/comment.type';
import {RouteComponentProps} from 'react-router';
import {useCommentFacade} from '../../hooks/facade/commentFacade.hook';
import Loader from '../dummie/Loader/loader';

type Props = RouteComponentProps<{urlPost: string}>

const CommentPost: React.FC<Props> = ({match, history}) => {
  const [{state: {active: postActive}, searchByUrl}] = usePostFacade();
  const [{state: {comments}, getComment, commentPost, loadingCommentStatus}] = useCommentFacade();

  const [comment, setComment] = useState('');

  useEffect(() => {
    if (! postActive) {
      searchByUrl(match.params.urlPost);
    }
    // eslint-disable-next-line
  }, [match.params.urlPost] );

  useEffect(() => {
    if (match.path.includes('/comment/') && postActive) {
      getComment(postActive.id);
    }
    // eslint-disable-next-line
  }, [postActive]);

  const SendComment = (): void => {
    commentPost(postActive?.id, {description: comment} as CommentType);
    setComment('');
  };
  return (
    <IonPage>
      <Loader isLoader={loadingCommentStatus} history={history}/>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <IonLabel>
                <h2 style={{paddingLeft: '11px'}}>Mostrando comentarios</h2>
              </IonLabel>
              <IonCard>
                <IonCardHeader>
                  <IonCardSubtitle>{postActive?.author?.name}</IonCardSubtitle>
                  <IonCardTitle>{postActive?.title}</IonCardTitle>
                </IonCardHeader>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <IonCard>
                <IonCardHeader style={{paddingBottom: '0px'}}>
                  <IonList lines="none">
                    <IonItem>
                      <IonAvatar slot="start">
                        <img src="assets/image/avatar-icon.jpg" alt="inconigt-avatar" />
                      </IonAvatar>
                      <IonLabel>
                        <h3>Incognito</h3>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                </IonCardHeader>
                <IonCardContent>
                  <IonTextarea
                    rows={4}
                    value={comment}
                    placeholder="Escriba aqui su comentario"
                    onIonChange={(e): void => setComment(e.detail.value as string)}
                  />
                  <IonButton
                    disabled={! postActive?.id || !comment || !loadingCommentStatus}
                    size="small"
                    onClick={(): void => SendComment()}
                  >
                    Comentar
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <IonList>
                {comments.sort((a, b) => {
                  return new Date(b.creationDate as Date).getTime() - new Date(a.creationDate as Date).getTime();
                }).map((value) =>
                  <IonItem key={value.id}>
                    <IonAvatar>
                      <img src="assets/image/avatar-icon.jpg" alt="comment-user" />
                    </IonAvatar>
                    <IonLabel style={{paddingLeft: '15px'}}>
                      <h3>{value.creationDate}</h3>
                      <h2 style={{whiteSpace: 'normal'}}>{value.description}</h2>
                    </IonLabel>
                  </IonItem>,
                )}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CommentPost;
