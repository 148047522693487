import React from 'react';
import {IonIcon, IonItem, IonLabel, IonList} from '@ionic/react';
import {arrowRoundBack, checkmarkCircleOutline, create, eye, remove, send, settings} from 'ionicons/icons';
import {Link} from 'react-router-dom';
import {Post} from '../../../models/post.model';
import {StatusPostEnum} from '../../../shared/types/enums/statusPostEnum';
import {prfEnum} from '../../../shared/types/enums/prfEnum';

interface Props {
    post: Post;
    onSelectOption: Function;
    rolUser: string;
    onSelectOptionPublish: Function;
    onSelectOptionReview: Function;
    onMoveToDraft: Function;
    onSelectEditConfig: Function;
    onSelectDelete: Function;
}

const ConfigOptionPost: React.FC<Props> = (
    {post, onSelectOption, rolUser, onSelectOptionPublish, onSelectOptionReview, onMoveToDraft,
      onSelectEditConfig, onSelectDelete},
) => {
  return (
    <IonList lines="none">
      <Link to={`/post/${post.postUrl}`} onClick={(): void => onSelectOption()}>
        <IonItem button>
          <IonIcon slot="end" icon={eye} />
          <IonLabel>Ver</IonLabel>
        </IonItem>
      </Link>
      {post.mode !== StatusPostEnum.PUBLISHED ?
            <Link to={`/edit-post/${post.postUrl}`} onClick={(): void => onSelectOption()}>
              <IonItem button>
                <IonIcon slot="end" icon={create}/>
                <IonLabel>Editar</IonLabel>
              </IonItem>
            </Link>:
        null}
      { post.mode !== StatusPostEnum.PUBLISHED && rolUser !== prfEnum.USR ?
        <IonItem button onClick={(): void => {
          onSelectOptionPublish({...post, mode: StatusPostEnum.PUBLISHED});
          onSelectOption();
        }}>
          <IonIcon slot="end" icon={send} />
          <IonLabel>Publicar</IonLabel>
        </IonItem> :
      null}
      {post.mode === StatusPostEnum.DRAFT && rolUser === prfEnum.USR?
            <IonItem button onClick={(): void => {
              onSelectOptionReview({...post, mode: StatusPostEnum.REVIEW});
              onSelectOption();
            }}>
              <IonIcon slot="end" icon={checkmarkCircleOutline} />
              <IonLabel>Enviar a revision</IonLabel>
            </IonItem>:
            null}
      {post.mode === StatusPostEnum.PUBLISHED ?
        <IonItem button onClick={(): void => {
          onMoveToDraft({...post, mode: StatusPostEnum.DRAFT});
          onSelectOption();
        }}>
          <IonIcon slot="end" icon={arrowRoundBack} />
          <IonLabel>Mover a draft</IonLabel>
        </IonItem>:
      null}
      {post.mode === StatusPostEnum.REVIEW && (rolUser !== prfEnum.ADM && rolUser !== prfEnum.PBLSHR ) ?
        <IonItem button onClick={(): void =>{
          onSelectEditConfig(post);
          onSelectOption();
        }}>
          <IonIcon slot="end" icon={settings} />
          <IonLabel>Editar Configuracion</IonLabel>
        </IonItem>:
      null}
      <IonItem button onClick={(): void => onSelectDelete(post)}>
        <IonIcon slot="end" icon={remove} />
        <IonLabel>Eliminar</IonLabel>
      </IonItem>
    </IonList>
  );
};
export default ConfigOptionPost;
