import React from 'react';

import './Media.css';
import {IonIcon} from '@ionic/react';
import {logoYoutube} from 'ionicons/icons';

interface Props {
    splashMedia: string;
    repIcon?: boolean;
}

const Splash: React.FC<Props> = ({splashMedia, repIcon}) => {
  return (
    <div>
      {splashMedia ? <img
        id="displayImage"
        alt="splash"
        src={splashMedia}
        className="splash-img animated fadeIn" /> : null}
      {repIcon ?
          <div className="splashMediaIcon">
            <IonIcon icon={logoYoutube} color="danger"/>
          </div> : null}
    </div>
  );
};

export default Splash;
