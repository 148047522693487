import React from 'react';
import {IonCol, IonGrid, IonInput, IonRow} from '@ionic/react';

import './TitleEditor.css';

interface Props {
    OnChangeTitle: Function;
    title: string;
    readonly?: boolean;
}

const TitleEditor: React.FC<Props> = ({title, OnChangeTitle, readonly = false}) => {
  return (
    <IonGrid>
      <IonRow no-wrap>
        {/* <IonCol size="12">*/}
        {/*  {title ? (*/}
        {/*    <h6 className="title-description animated fadeIn slow" >Title</h6>*/}
        {/* ): <h6 style={{visibility: 'hidden'}}>hidden</h6>}*/}
        {/* </IonCol>*/}
        <IonCol size="12">
          <IonInput
            readonly={readonly}
            className="title-input"
            placeholder="Titulo..."
            type="text"
            value={title}
            onIonChange={(event): void => OnChangeTitle(event.detail.value as string)} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TitleEditor;
