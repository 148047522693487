/* eslint-disable */
import {whatever} from '../customType';
import {YoutubeType} from "../types/youtube.type";

export const onErrorUpload = (data: whatever): void => {
  let message = data;
  try {
    const errorResponse = JSON.parse(data);
    message = errorResponse.error.message;
  } finally {
    alert(message);
  }
};

export const onProgressUpload = (data: whatever): number => {
  const currentTime = Date.now();
  const bytesUploaded = data.loaded;
  const totalBytes = data.total;
  const bytesPerSecond = bytesUploaded / ((currentTime - Date.now()) / 1000);
  const estimatedSecondsRemaining = (totalBytes - bytesUploaded) / bytesPerSecond;
  const percentageComplete = (bytesUploaded * 100) / totalBytes;
  console.log('estimatedSecondsRemaining', estimatedSecondsRemaining);
  console.log('percentageComplete', percentageComplete);
  return percentageComplete / 100;
};

export const onCompleteUpload = (data: whatever): YoutubeType => {
  const uploadResponse = JSON.parse(data);
  return {
    channelTittle : uploadResponse.snippet.channelTitle,
    description : uploadResponse.snippet.description,
    displayImage: uploadResponse.snippet.thumbnails.medium.url,
    publishedDate: uploadResponse.snippet.publishedAt,
    selected: false,
    title:uploadResponse.snippet.title,
    videoId : uploadResponse.id
  }
};
