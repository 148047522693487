import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {PreferenceType} from '../shared/types/preference.type';

export type PreferenceState = EntityState<PreferenceType>

@StoreConfig({name: 'preference', resettable: true})
export class PreferenceStore extends EntityStore<PreferenceState, PreferenceType> {
  // eslint-disable-next-line
    constructor() {
    super();
  }
}

export const preferenceStore = new PreferenceStore();
