/* eslint-disable @typescript-eslint/no-useless-constructor */
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {CommentType} from '../shared/types/comment.type';

export type CommentState = EntityState<CommentType>
@StoreConfig({name: 'comment'})
export class CommentStore extends EntityStore<CommentState, CommentType> {
  constructor() {
    super();
  }
}
export const commentStore = new CommentStore();
