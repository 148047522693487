import React, {ReactNode, useEffect} from 'react';
import {Redirect, Route, HashRouter as Router} from 'react-router-dom';
import {IonApp, IonRouterOutlet, IonHeader} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import '../node_modules/animate.css/animate.min.css';
/* Theme variables */
import './theme/variables.css';
import './styles/Draft.css';

/* Component Page */
import Home from './components/smart/Home';
import CreatePost from './components/smart/CreatePost';
import PostView from './components/smart/PostView';
import Profile from './components/smart/Profile';
import EditPost from './components/smart/EditPost';
import Settings, {capitalize, changeFavicon} from './components/smart/Settings/Settings';
import CommentPost from './components/smart/CommentPost';
import CmsToolbar from './components/dummie/toolBar/CmsToolbar';
import {useConfigurationFacade} from './hooks/facade/configurationFacade.hook';
import ToastError from './components/dummie/TostError/ToastError';
import {useGlobalFacade} from './hooks/facade/globalFacade.hook';
import {URL_IMAGE_SMALL} from './shared/ConstantUrl';

const App: React.FC = () => {
  const [{getDomain, favicon}] = useConfigurationFacade();
  const [{errorHttp, renewToken}] = useGlobalFacade();

  useEffect(() => {
    getDomain();
    document.title = capitalize(window.location.hostname);
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (favicon) changeFavicon(URL_IMAGE_SMALL+favicon);
    // eslint-disable-next-line
  }, [favicon]);

  useEffect(() => {
    if (errorHttp?.code === 401) {
      renewToken();
    }
    // eslint-disable-next-line
  }, [errorHttp?.code]);


  return (
    <Router>
      <IonApp>
        <>
          <IonHeader>
            <CmsToolbar />
          </IonHeader>
          <ToastError isOpen={errorHttp}/>
          <IonRouterOutlet style={{marginTop: '3.5em'}}>
            <Route exact path="/post" render={(): ReactNode => <Redirect to="/"/>}/>
            <Route path="/" component={Home} exact/>
            <Route path="/new-post" component={CreatePost} exact/>
            <Route path="/post/:urlPost" component={PostView}/>
            <Route path="/profile" component={Profile}/>
            <Route path="/edit-post/:urlPost" component={EditPost} exact/>
            <Route path="/settings" component={Settings} exact />
            <Route path="/comment/:urlPost" component={CommentPost} exact />
          </IonRouterOutlet>
        </>
      </IonApp>
    </Router>
  );
};

export default App;
