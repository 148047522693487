import React from 'react';
import {IonProgressBar} from '@ionic/react';
import * as H from 'history';


interface Props {
  isLoader: boolean;
  history?: H.History;
}

const Loader: React.FC<Props> = ({history, isLoader}) => {
  return (
    <>
      {history?.location.pathname === window.location.hash.split('#')[1] && !isLoader ?
          <IonProgressBar className="animated fadeIn" type="indeterminate"/> :
          null
      }
    </>
  );
};
export default Loader;
