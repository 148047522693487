/* eslint-disable no-unused-vars */
import {Post} from '../../models/post.model';
import {Observable, Subscription} from 'rxjs';
import {useEffect, useState} from 'react';
import {postQuery} from '../../query/post.query';
import {postService} from '../../services/post.service';
import {ID} from '@datorama/akita';
import {whatever} from '../../shared/customType';
import {ResponseError} from '../../shared/types/genericResponse.type';


interface PostState {
    posts: Post[];
    active: Post | null;
}

export function onEmit<T>(source$: Observable<T>, nextFn: (value: T) => void): Subscription {
  // eslint-disable-next-line no-console
  return source$.subscribe((value) => nextFn(value), console.error);
}

export function usePostFacade(): [
    {state: PostState; setActive: Function; updateActive: Function; addPost: Function; removeActive: Function;
    loadAll: Function; searchByUrl: Function;searchByUser: Function; updatePost: Function; getReview: Function;
    removePost: Function; addLikePost: Function; loadingStatePost: boolean; errorStatePost: ResponseError;
    resetStore: Function;deletePost: Function;}
    ] {
  const [state, setState] = useState<PostState>({posts: [], active: null});
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const setActive = (id: ID): void => postService.setActive(id);
  const updateActive = (post: Post): void => postService.updateActive(post);
  const addPost = (post: Post): whatever => postService.add(post).subscribe();
  const removeActive = (id: ID): void => postService.removeActive(id);
  const loadAll = (length?: number, addPublish?: boolean): void => postService.loadAll(length, addPublish);
  const searchByUrl = (urlPost: string): void => postService.getByUrl(urlPost);
  const searchByUser = (): void => postService.getByUser();
  const updatePost = (post: Post): whatever => postService.update(post).subscribe();
  const getReview = (): whatever => postService.getReview().subscribe();
  const removePost = (id: ID): void => postService.removePost(id);
  const addLikePost = (id: ID): void => postService.addLike(id);
  const resetStore = (): void => postService.reset();
  const deletePost = (id: ID): void => postService.delete(id);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<Post[]>(postQuery.posts$, (posts) => setState((state) => ({...state, posts}))),
      onEmit<Post>(postQuery.activePost$, (active) => setState((state) => ({...state, active}))),
      onEmit<boolean>(postQuery.loading$, (value) => setLoading(value)),
      onEmit(postQuery.error$, (value) => setError(value)),
    ];

    return (): void => {
      subscriptions.map( (sub) => sub.unsubscribe());
    };
    // eslint-disable-next-line
  }, []);

  return [
    {
      state,
      setActive,
      updateActive,
      addPost,
      removeActive,
      loadAll,
      searchByUrl,
      searchByUser,
      updatePost,
      getReview,
      removePost,
      addLikePost,
      loadingStatePost: loading,
      errorStatePost: error,
      resetStore,
      deletePost,
    },
  ];
}
