import moment from 'moment';
import 'moment/locale/es';

export const formatDate = (date: string, pattern: string, locale: string): string => {
  const aux = moment(new Date(date));
  aux.locale(locale);
  return aux.format(pattern);
};

export const getParamsUrl = (searchUrl: string, paramName: string): string | null => {
  const searchParams = new URLSearchParams(searchUrl);
  return searchParams.get(paramName);
};

export const gapi = window.gapi;
