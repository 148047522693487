export const CATEGORIES = 'category';
export const API_CMS_URL = process.env.REACT_APP_API_CMS_URL as string;
export const API_AUTH_URL = process.env.REACT_APP_AUTH_URL as string;
export const API_FACEBOOK_URL = 'https://graph.facebook.com/v5.0/';
export const USER_PROFILE_URL = 'userProfiles';
export const MY_PROFILE_URL = 'myProfile';
export const USER_LIST_URL = 'userProfileList';
export const PREFERENCES = 'preference';
export const POST = 'posts';
export const DRAFT = 'drafts';
export const API_YOUTUBE = 'https://www.googleapis.com/upload/youtube/v3/videos';
export const URL_IMAGE_MEDIUM = `${window.location.origin}/medium/`;
export const URL_IMAGE_SMALL = `${window.location.origin}/small/`;

