/* eslint-disable */
import React, {useEffect} from 'react';
import {IonCol, IonGrid, IonItem, IonList, IonRow} from '@ionic/react';
import {ReactFacebookLoginInfo} from 'react-facebook-login';

import './Style.css';
import FacebookMedia from '../../dummie/SocilaMedia/FacebookMedia/FacebookMedia';
import {useSocialMediaFacade} from '../../../hooks/facade/socialMediaFacade.hook';
import FacebookLabel from '../../dummie/SocilaMedia/FacebookMedia/FacebookLabel';
import GoogleMedia from '../../dummie/SocilaMedia/GoogleMedia/GoogleMedia';
import {RouteComponentProps, withRouter} from 'react-router';

interface Prop extends RouteComponentProps{
  className?: string;
}
const ConnectAccount: React.FC<Prop> = ({className, history}) => {
  const [{getAll, medias, connect: connectWithFace, delete: deleteSocialFb, connectWithGoogle}] = useSocialMediaFacade();

  useEffect(()=> {
    if (history.location.pathname === '/settings/') getAll();
    // eslint-disable-next-line
    }, [history.location.pathname]);

  const saveFacebookAccount = (userInfo: ReactFacebookLoginInfo): void => {
    if (userInfo.accessToken) {
      connectWithFace(
          {
            keyAccessResource: userInfo.accessToken,
            userName: userInfo.name,
            typeResource: 'facebook',
            resourceId: userInfo.id,
          },
      );
    }
  };

  const saveGoogleAccount = (code: string): void => {
    connectWithGoogle({code, redirect_uri: window.location.origin});
  };

  return (
    <IonGrid className={className ? className : ''}>
      <IonRow>
        <IonCol>
          <IonList>
            <IonItem id="itemAccount">
              <FacebookMedia
                onFacebookUserInfo={saveFacebookAccount}
                status={!medias.filter((value) => value.typeResource === 'facebook').length}
              />
              {medias.length && medias.filter((value) => value.typeResource === 'facebook').length ?
                  (
                    <FacebookLabel
                      socialMedia={medias.filter((value) => value.typeResource === 'facebook')[0]}
                      onLogOut={(id: number): void => deleteSocialFb(id)} />
                  ):
                  <h2 style={{color: '#717171', fontSize: '16px', marginLeft: '15px'}}>Connectar con mi pagina</h2>
              }
            </IonItem>
            <br/>
            <IonItem>
              <GoogleMedia credentialGoogle={saveGoogleAccount}/>
                {medias.length && medias.filter(value => value.typeResource === 'youtube').length > 0 ?
                    (
                        <FacebookLabel
                            onLogOut={(id: number): void => deleteSocialFb(id)}
                            socialMedia={medias.filter(value => value.typeResource === 'youtube')[0]}
                        />
                    ):
                    <h2 style={{color: '#717171', fontSize: '16px', marginLeft: '15px'}}>Connectar con mi cuenta</h2>
                }
            </IonItem>
          </IonList>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default withRouter(ConnectAccount);
