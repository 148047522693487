export const TOKEN = 'token';
// eslint-disable-next-line
export const PATTER_HTTP_URL = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,50}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
export const INPUT_TYPE_FILE = 'application/MOV,application/MPEG4,application/MP4,application/AVI,application/WMV,' +
  'application/MPEGPS,application/FLV,application/3GPP,application/WebM,application/DNxHR,application/ProRes,' +
  'application/CineForm,application/HEVC';
export const ANIMATION_FADE_IN_RIGHT = 'animated fadeInRight delay-200ms faster';
export const ANIMATION_FADE_IN_LEFT = 'animated fadeInLeft delay-200ms faster';
export const ANIMATION_FADE_IN_DOWN = 'animated fadeInDown delay-200ms faster';
export const ANIMATION_FADE_IN_UP = 'animated fadeInUp delay-200ms faster';
export const ANIMATION_FADE_OUT_RIGHT = 'animated bounceOut delay-200ms faster';
export const ANIMATION_BOUNCE_IN_DOWN_RIGHT = 'animated bounceInDown delay-600ms faster';
export const ANIMATION_SLIDE_IN_RIGHT = 'animated slideInRight delay-600ms faster';
export const ANIMATION_SLIDE_IN_LEFT = 'animated slideInLeft delay-600ms faster';
export const ANIMATION_FLIP_IN_Y = 'animated flipInY delay-600ms faster';
export const PROFILE = 'usr_prof';
// export const API_KEY = 'AIzaSyBxk0u_b5c-bbqKPuvY8VrcwRO2YgJ-T5w';
export const API_KEY = 'AIzaSyAKJTv-PBzPekraARXaFjovtjV-calnQRU';
// eslint-disable-next-line
export const YOUTUBEREGEXP = RegExp('^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$');
