import {useEffect, useState} from 'react';
import {configurationQuery} from '../../query/configuration.query';
import {Subscription} from 'rxjs';
import {onEmit} from './postFacade.hook';
import {ConfigurationState} from '../../stores/configuration.store';
import {configurationService} from '../../services/configuration.service';
import {ID} from '@datorama/akita';
import {whatever} from '../../shared/customType';
import {ResponseError} from '../../shared/types/genericResponse.type';

export function useConfigurationFacade(): [
    {logo: string;favicon: string;idDomain: ID | undefined;updateConfigDomain: Function;getDomain: Function;
    saveDomain: Function;updateDomain: Function;errorStateConfig: ResponseError;change: whatever;}
    ] {
  const [logo, setLogo] = useState<string>('');
  const [favicon, setFavicon] = useState<string>('');
  const [idDomain, setIdDomain] = useState<ID | undefined>();
  const [change, setChange] = useState<whatever>();
  const [error, setError] = useState();

  const updateConfigDomain = (config: ConfigurationState): void => configurationService.updateConfiguration(config);
  const getDomain = (): whatever => configurationService.getDomain().subscribe();
  const saveDomain = (confi: ConfigurationState): whatever => configurationService.saveDomainConfig(confi).subscribe();
  const updateDomain = (config: ConfigurationState): whatever => configurationService.updateDomain(config).subscribe();

  useEffect(() => {
    const subscription: Subscription[] = [
      onEmit(configurationQuery.logo$, (logo) => setLogo(logo)),
      onEmit(configurationQuery.favicon$, (favicon) => setFavicon(favicon)),
      onEmit(configurationQuery.idDomain$, (id) => setIdDomain(id ? id : undefined)),
      onEmit(configurationQuery.change$, (change) => setChange(change)),
      onEmit(configurationQuery.error$, (err) => setError(err)),
    ];
    return (): void => {
      subscription.map((sub) => sub.unsubscribe());
    };
  }, []);

  return [
    {
      logo,
      favicon,
      idDomain,
      updateConfigDomain,
      getDomain,
      saveDomain,
      updateDomain,
      errorStateConfig: error,
      change,
    },
  ];
}
