/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {blockType, inline, optionsEditor} from './ConfigEditor';
import {RawDraftContentState} from 'draft-js';
import './Editor.css';

interface Props {
    onEditorChange?: Function;
    editor: RawDraftContentState | undefined;
    readOnly?: boolean;
    toolbarOnFocus?: boolean;
}
const EditorText: React.FC<Props> = ({onEditorChange, editor, readOnly =false, toolbarOnFocus}) => {
  const [hidden, setHidden] = useState<boolean>();
  const validEditor = (): boolean => {
    return !(editor?.blocks[0].text === '');
  };

  return (
    <Editor
      initialContentState={editor}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onContentStateChange={(state): void => onEditorChange ? onEditorChange(state ? state : ''): null}
      toolbar={{options: optionsEditor, blockType, inline}}
      placeholder="Contenido..."
      toolbarOnFocus={toolbarOnFocus}
      onFocus={(): void => setHidden(false)}
      onBlur={(): void => setHidden(true) }
      toolbarHidden={!validEditor() || editor === undefined || hidden || readOnly}
      readOnly={readOnly}
    />
  );
};

export default EditorText;

