import {ID, Store, StoreConfig} from '@datorama/akita';
import {whatever} from '../shared/customType';

export interface ConfigurationState {
    id? : ID;
    favicon: string;
    logo: string;
    change? : whatever;
}

function initialState(): ConfigurationState {
  return {
    favicon: 'assets/image/logo-preloader.gif',
    logo: 'assets/image/logo-preloader.gif',
  };
}

@StoreConfig({name: 'configuration'})
export class ConfigurationStore extends Store<ConfigurationState> {
  constructor() {
    super(initialState());
  }
}

export const configurationStore = new ConfigurationStore();
