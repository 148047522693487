import React from 'react';
import {Post} from '../../../models/post.model';
import {IonCol, IonGrid, IonRow} from '@ionic/react';
import PostDisplay from './postDisplay';
import {RawDraftContentState} from 'draft-js';
import {Link} from 'react-router-dom';
import {whatever} from '../../../shared/customType';


interface Props {
    posts: Post[];
    onSelectPost: Function;
    fetchMoreData: () => whatever;
}

export const parseSummary = (post: RawDraftContentState): string => {
  return post.blocks[0].text;
};

const PostPresentation: React.FC<Props> = ({posts, onSelectPost}) => {
  return (
    <IonGrid >
      <IonRow >
        {
          posts.map((post) => (
            <IonCol style={{padding: '0 15px'}} key={post.id}
              sizeXl="5" offsetXl="3" sizeSm="5" sizeMd="8" offsetMd="2" offsetSm="3" sizeXs="12" offsetXs="0" >
              <Link to={`/post/${post.postUrl}`} onClick={(): void => onSelectPost(post)}>
                <PostDisplay
                  picture={post.mediaUrl}
                  title={post.title}
                  video={post.youtubeId}
                  author={post.author ? post.author.name: ''}
                  summary={post.description ? parseSummary(JSON.parse(post.description)) : 'Not description'}
                  likes={post.like}
                />
              </Link>
            </IonCol>
          ))
        }
      </IonRow>
    </IonGrid>
  );
};

export default PostPresentation;
