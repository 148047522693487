import React, {useState} from 'react';
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonFab,
  IonFabButton,
} from '@ionic/react';
import {whatever} from '../../../shared/customType';
import {checkmark, search} from 'ionicons/icons';
import VideoDisplayer from './VideoDisplayer';
import {youtubeService} from '../../../services/youtube.service';
import {YoutubeType} from '../../../shared/types/youtube.type';
import {YOUTUBEREGEXP} from '../../../shared/ConstantDataStructure';

interface Props {
  selected: Function;
}

const VideoByUrl: React.FC<Props> = ({selected}) => {
  const [url, setUrl] = useState<string>('');
  const [returnVal, setReturnVal] = useState<YoutubeType>();
  const handleURL = (value: string): void => {
    setUrl(value);
  };

  const idGetbacker = (): string => {
    // eslint-disable-next-line
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match&&match[7].length === 11) {
      return match[7];
    }
    return '';
  };

  const executeSearch = (): void => {
    // console.log(YOUTUBEREGEXP.test(url));
    // console.log(idGetbacker());
    if (YOUTUBEREGEXP.test(url) && idGetbacker()!== '') {
      youtubeService.getVideoList(idGetbacker()).subscribe((value: YoutubeType[]) => {
        if (value) {
          // console.log('Value to return in serch by url: ', value);
          setReturnVal(value[0]);
        }
      });
    }
  };
  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="9" offsetMd="1" sizeXs="10" offsetXs="0" sizeSm="5" offsetSm="1">
            <IonItem>
              <IonLabel position="floating">Pegar URL Youtube</IonLabel>
              <IonInput value={url} onIonChange={(e: whatever): void => handleURL(e.target.value)} />
            </IonItem>
          </IonCol>
          <IonCol sizeMd="2" offsetMd="0" sizeXs="2" offsetXs="0">
            <IonItem lines="none" className="iconSearchYoutube" button onClick={(): void => executeSearch()}>
              <IonIcon icon={search} />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {returnVal ? <VideoDisplayer url={returnVal?.videoId}/> :
              <div style={{height: '100%', width: '100%'}}>
                <img src="assets/image/loadVideo.jpg" alt="static_image" width="100%" height="100%" />
              </div>}
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton
          disabled={!url}
          onClick={(): void => {
            if (url && returnVal) selected(returnVal);
          }}>
          <IonIcon icon={checkmark}/>
        </IonFabButton>
      </IonFab>
    </>
  );
};

export default VideoByUrl;
