import {CommentType} from '../../shared/types/comment.type';
import {useEffect, useState} from 'react';
import {Subscription} from 'rxjs';
import {onEmit} from './postFacade.hook';
import {commentQuery} from '../../query/comment.query';
import {ID} from '@datorama/akita';
import {whatever} from '../../shared/customType';
import {commentService} from '../../services/comment.service';
import {ResponseError} from '../../shared/types/genericResponse.type';

interface CommentState {
    comments: CommentType[];
    activeComment: CommentType | null;
}

export function useCommentFacade(): [{state: CommentState;getComment: Function;commentPost: Function;
      loadingCommentStatus: boolean;errorStatusComment: ResponseError; }] {
  const [state, setState] = useState<CommentState>({comments: [], activeComment: null});
  const [loadingCommentStatus, setLoadingCommentStatus] = useState();

  const getComment = (id: ID): whatever => commentService.getPostWithComment(id).subscribe();
  const commentPost = (id: ID, comment: CommentType): whatever => commentService.addComment(comment, id).subscribe();
  const [error, setError] = useState();

  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<CommentType[]>(commentQuery.comment$,
          (comments) => setState((state) => ({...state, comments}))),
      onEmit<CommentType>(commentQuery.activeComment$,
          (activeComment) => setState((state) => ({...state, activeComment}))),
      onEmit<boolean>(commentQuery.loading$, (value) => setLoadingCommentStatus(value) ),
      onEmit(commentQuery.error$, (value) => setError(value)),
    ];

    return (): void => {
      subscriptions.map( (sub) => sub.unsubscribe());
    };
    // eslint-disable-next-line
  },[]);

  return [
    {
      state,
      getComment,
      commentPost,
      loadingCommentStatus,
      errorStatusComment: error,
    },
  ];
}
