import React from 'react';
import {IonLabel, IonSegment, IonSegmentButton} from '@ionic/react';
import {StatusPostEnum} from '../../../shared/types/enums/statusPostEnum';

interface Props {
    onStatus: Function;
    defaultValue?: StatusPostEnum;
}

const PostListStatusSegment: React.FC<Props> = ({onStatus, defaultValue}) => {
  onStatus(defaultValue);
  return (
    <IonSegment style={{borderBottom: 'solid #3880ff1f 2px'}} onIonChange={(e): void => onStatus(e.detail.value)}>
      {Object.keys(StatusPostEnum).map((value) =>
        <IonSegmentButton
          key={value}
          checked={defaultValue === value}
          slot="start"
          value={value}>
          <IonLabel style={{textTransform: 'capitalize'}}>{value.toLowerCase()}</IonLabel>
        </IonSegmentButton>,
      )}
    </IonSegment>
  );
};

export default PostListStatusSegment;
