import React from 'react';
import {IonCol, IonGrid, IonRow} from '@ionic/react';
import {ANIMATION_FLIP_IN_Y} from '../../../shared/ConstantDataStructure';

interface Props {
  url: string;
}
const VideoDisplayer: React.FC<Props> = ({url}) => {
  return (
    <>
      <IonGrid>
        <IonRow className={ANIMATION_FLIP_IN_Y}>
          <IonCol sizeXs="12" offsetXs="0" sizeMd="10" offsetMd="1">
            <iframe title="Video Displayer" width="100%" height="315" src={`https://www.youtube.com/embed/${url}`} frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default VideoDisplayer;
