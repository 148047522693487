import {QueryEntity} from '@datorama/akita';
import {PreferenceState, preferenceStore} from '../stores/preference.store';
import {PreferenceType} from '../shared/types/preference.type';
import {Observable} from 'rxjs';

export class PreferenceQuery extends QueryEntity<PreferenceState, PreferenceType> {
    preferences$: Observable<PreferenceType[]> = this.selectAll();
    activePreference$: Observable<PreferenceType> = this.selectActive<PreferenceType>() as Observable<PreferenceType>;
}

export const preferenceQuery = new PreferenceQuery(preferenceStore);
