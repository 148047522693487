import React from 'react';
import {IonIcon} from '@ionic/react';
import {logoGoogle} from 'ionicons/icons';
import {
  AUTHG_ACCESS_TYPE, AUTHG_CLIENT_ID,
  AUTHG_PROMPT, AUTHG_RESPONSE_TYPE,
  AUTHG_SCOPE,
} from '../../../../shared/ConstantLabel';
import {GoogleLogin} from 'react-google-login';
import {whatever} from '../../../../shared/customType';

interface Props {
    credentialGoogle: Function;
}

const GoogleMedia: React.FC<Props> = ({credentialGoogle}) => {
  const authWithGoogle = (data: whatever): void =>{
    if (data?.code) {
      credentialGoogle(data.code);
    }
  };

  return (
    <>
      <GoogleLogin
        styile={{backgroundColor: 'white'}}
        clientId={AUTHG_CLIENT_ID}
        render={(renderProps: whatever): whatever => (<IonIcon
          className="icon-social-media"
          icon={logoGoogle}
          color="primary"
          size="large"
          onClick={renderProps.onClick}
        />
        )}
        buttonText="Login"
        onSuccess={authWithGoogle}
        onFailure={authWithGoogle}
        cookiePolicy={'single_host_origin'}
        prompt={AUTHG_PROMPT}
        accessType={AUTHG_ACCESS_TYPE}
        scope={AUTHG_SCOPE}
        responseType={AUTHG_RESPONSE_TYPE}
      />
    </>
  );
};
export default GoogleMedia;
