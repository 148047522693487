/* eslint-disable @typescript-eslint/no-explicit-any */
import {Plugins} from '@capacitor/core';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {whatever} from './customType';
const {Storage} = Plugins;

export const setObject = (data: any, key: string): void => {
  Storage.set({key: key, value: JSON.stringify(data)});
};

export const getObject$ = <T>(key: string): Observable<T|null> => {
  return from(Storage.get({key: key})).pipe(
      map((value) => {
        const response: T | null = JSON.parse(value.value as string);
        return response;
      }),
  );
};

export const removeObject$ = (key: string| string[]): Observable<void> | whatever => {
  if (typeof key === 'string') {
    return from(Storage.remove({key: key}));
  } else if (key instanceof Array) {
    key.map((value) => Storage.remove({key: value}));
  }
};

export const clear$ = (): Observable<void> => {
  return from(Storage.clear());
};
