import {Store, StoreConfig} from '@datorama/akita';
import {ResponseError} from '../shared/types/genericResponse.type';

export interface GlobalState {
  errorHttp: ResponseError | null;
  tokenYoutubeActive: string;
  hasMorePost: boolean;
}
function createInitialState(): GlobalState {
  return {
    errorHttp: null,
    tokenYoutubeActive: '',
    hasMorePost: true,

  };
}

@StoreConfig({name: 'global'})
export class GlobalStore extends Store<GlobalState> {
  constructor() {
    super(createInitialState());
  }
}


export const globalStore = new GlobalStore();
