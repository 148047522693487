import React, {useEffect, useState} from 'react';
import {IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonIcon, IonPage, IonRow} from '@ionic/react';
import {ContentState, convertToRaw, RawDraftContentState} from 'draft-js';
import {save} from 'ionicons/icons';
import EditorText from '../dummie/editor/TextEditor/Editor';
import TitleEditor from '../dummie/editor/TitleEditor/TitleEditor';
import Splash from '../dummie/editor/MediaEditor/splash';
import {usePostFacade} from '../../hooks/facade/postFacade.hook';
import {createPost} from '../../models/post.model';
import UploadMedia from '../dummie/UploadMedia/UploadMedia';
import {Media} from '../../shared/types/media.type';
import {AlertModal} from '../dummie/Modal/AlertModal';
import Principal from '../dummie/Youtube/Principal';
import {whatever} from '../../shared/customType';
import VideoDisplayer from '../dummie/Youtube/VideoDisplayer';
import Loader from '../dummie/Loader/loader';
import {RouteComponentProps} from 'react-router';
import {YoutubeType} from '../../shared/types/youtube.type';

const CreatePost: React.FC<RouteComponentProps> = ({history}) => {
  const [editor, setEditor] = useState<RawDraftContentState | undefined >(() => {
    const emptyState = ContentState.createFromText('');
    return convertToRaw(emptyState);
  });
  const [title, setTitle] = useState<string>('');
  const [splashMedia, setSplashMedia] = useState('');
  const [hiddenEditor, setHiddenEditor] = useState(true);
  const [modalYoutube, setModalYoutube] = useState(false);
  const [youtubeId, setYoutubeId] = useState('');

  const [{addPost, loadingStatePost, errorStatePost}] = usePostFacade();

  const cleanInfo = (): void => {
    setHiddenEditor(false);
    setTimeout(() => setHiddenEditor(true), 100);
    setEditor(undefined);
    setTitle('');
    setSplashMedia('');
    setYoutubeId('');
  };

  useEffect(() => {
    if (loadingStatePost && !errorStatePost) {
      cleanInfo();
    }
    // eslint-disable-next-line
  }, [loadingStatePost]);

  const savePost = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const post = createPost(
        {description: JSON.stringify(editor),
          mediaUrl: splashMedia,
          title: title,
          youtubeId: youtubeId},
    );
    addPost(post);
  };

  return (
    <IonPage>
      <Loader isLoader={loadingStatePost} history={history} />
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol
              style={{height: '65px', paddingLeft: '15px'}}
              sizeXl="8" offsetXl="2"
              sizeSm="10"
              offsetSm="1"
              sizeXs="12"
              offsetXs="0">
              <UploadMedia onMedia={(media: Media): void => {
                setSplashMedia(media.base64);
                setYoutubeId('');
              }}
              loadYoutube={(e: boolean): void => setModalYoutube(e)}/>
            </IonCol>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              {splashMedia ?
                  <Splash splashMedia={splashMedia} repIcon={!!youtubeId} />:
                  youtubeId ? <VideoDisplayer url={youtubeId} />: null}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <TitleEditor OnChangeTitle={setTitle} title={title}/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              {hiddenEditor ? <EditorText onEditorChange={setEditor} editor={editor}/> : null}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonRow>
        <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
          <IonFab horizontal="end" vertical="bottom">
            <IonFabButton color="second" disabled={!loadingStatePost} onClick={savePost}>
              <IonIcon icon={save} />
            </IonFabButton>
          </IonFab>
        </IonCol>
      </IonRow>
      <AlertModal isOpen={modalYoutube} dismiss={(e: whatever): void => setModalYoutube(e)} title="Youtube Video">
        <Principal selectedVideo={(e: YoutubeType): void => {
          if (e) {
            setModalYoutube(false);
            setYoutubeId(e.videoId);
            setSplashMedia(e.displayImage);
          }
        }}
        />
      </AlertModal>
    </IonPage>
  );
};


export default CreatePost;
