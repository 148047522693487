import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {UserProfileType} from '../shared/types/UserProfile.type';

export type UserProfileState = EntityState<UserProfileType>

@StoreConfig({name: 'UserProfile', resettable: true})
export class UserProfileStore extends EntityStore<UserProfileState, UserProfileType> {
  // eslint-disable-next-line
  constructor() {
    super();
  }
}

export const userProfileStore = new UserProfileStore();
