import React, {useState} from 'react';
import {IonIcon, IonLabel} from '@ionic/react';
import {heart} from 'ionicons/icons';

interface Props {
    likeCount: number;
    onLikeCount?: Function;
    disable?: boolean;
}

const Like: React.FC<Props> = ({likeCount = 0, onLikeCount, disable = false}) => {
  const [animateLike, setAnimateLike] = useState('');
  const [animateStatus, setAnimateStatus] = useState<boolean>();
  return (
    <>
      <IonIcon
        className={`${!disable ? 'pointer' : ''} padding-icon animated ${animateStatus ? animateLike: ''}`}
        icon={heart}
        size="large"
        color='danger'
        onClick={(): void => {
          if (!disable) {
            setAnimateStatus(true);
            setAnimateLike('tada');
            setTimeout(()=> setAnimateStatus(false), 1000);
            if (onLikeCount) onLikeCount();
          }
        }}
      />
      <IonLabel className="count-like animated zoomIn">
        {likeCount}
      </IonLabel>
    </>
  );
};

export default Like;
