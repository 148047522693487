/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {AlertModalModel} from '../../../models/alertModal.model';
import React from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar, IonModal} from '@ionic/react';

export const AlertModal: React.FC<AlertModalModel> = ({isOpen, dismiss, ModalContent, title, children}) => {
  return (
    <>{ isOpen ?
      <IonModal isOpen={isOpen} onDidDismiss={() => dismiss(false)}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons slot={'end'}>
              <IonButton color={'primary'} onClick={() => dismiss(false)}>
                Cerrar
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <div style={{height: '100%'}}>
          {isOpen ? children ? <IonContent>{children}</IonContent>: ModalContent : null}
        </div>
      </IonModal> : null}
    </>
  );
};
