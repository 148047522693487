import {QueryEntity} from '@datorama/akita';
import {CategoryState, categoryStore} from '../stores/category.store';
import {CategoryModel} from '../models/Category.model';
import {Observable} from 'rxjs';
import {ResponseError} from '../shared/types/genericResponse.type';

export class CategoryQuery extends QueryEntity<CategoryState, CategoryModel> {
  category$: Observable<CategoryModel[]> = this.selectAll();
  error$: Observable<ResponseError> = this.selectError();
}

export const categoryQuery = new CategoryQuery(categoryStore);
