/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import {GenericPopModel} from '../../../models/GenericPop.model';
import {IonCol, IonGrid, IonPopover, IonRow} from '@ionic/react';
import {ANIMATION_FADE_OUT_RIGHT} from '../../../shared/ConstantDataStructure';

const GenericPopOver: React.FC<GenericPopModel> = ({open, onClose, children, event, willClose}) => {
  return (
    <>
      <IonPopover isOpen={open} onDidDismiss={(e) => onClose(e)} event={event} showBackdrop={false}>
        <IonGrid className={willClose ? ANIMATION_FADE_OUT_RIGHT : ''}>
          <IonRow>
            <IonCol>
              {children}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonPopover>
    </>
  );
};

export default GenericPopOver;
