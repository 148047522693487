import React from 'react';
import {Post} from '../../../models/post.model';
import {StatusPostEnum} from '../../../shared/types/enums/statusPostEnum';
import {IonImg, IonItem, IonLabel, IonList, IonThumbnail} from '@ionic/react';
import {parseSummary} from './postPresentation';
import {URL_IMAGE_SMALL} from '../../../shared/ConstantUrl';

interface Props {
    postsList: Post[];
    filter: StatusPostEnum;
    onSelectPost: Function;
}

export const urlMedia = (post: Post): string => {
  let url = '';
  if (post.youtubeId) {
    url = post.mediaUrl;
  }
  if (!post.youtubeId && post.mediaUrl) {
    url = URL_IMAGE_SMALL+post.mediaUrl;
  }
  // eslint-disable-next-line no-console
  console.log(url);
  return url;
};
const PostList: React.FC<Props> = ({postsList, filter, onSelectPost}) => {
  return (
    <IonList>
      {postsList.length ?(
            <>
              {postsList.filter((value) => value.mode === filter.toLowerCase()).map((post) =>
                <IonItem key={post.id} onClick={(event): void => onSelectPost({event, post})}>
                  <IonThumbnail>
                    <IonImg src={post.mediaUrl? urlMedia(post) :
                            'assets/image/noImage.jpg'
                    }
                    />
                  </IonThumbnail>
                  <IonLabel style={{marginLeft: '15px'}}>
                    <h1 style={{textOverflow: 'ellipsis', overflow: 'inherit', fontSize: '20px'}}>{post.title}</h1>
                    <p>{post.description ? parseSummary(JSON.parse(post.description)) : 'Not description'} </p>
                  </IonLabel>
                </IonItem>,
              )}
            </>): null}
    </IonList>
  );
};

export default PostList;
