/* eslint-disable no-console */
import {useEffect, useState} from 'react';
import {Subscription} from 'rxjs';
import {onEmit} from './postFacade.hook';
import {socialMediaService} from '../../services/socialMedia.service';
import {SocialMediaType} from '../../shared/types/socialMedia.type';
import {SocialMediaModel} from '../../models/socialMedia.model';
import {socialMediaQuery} from '../../query/socialMedia.query';
import {ConnectGoogleCredentials} from '../../shared/customType';

export function useSocialMediaFacade(): [
    {getAll: Function;medias: SocialMediaModel[]; connect: Function; delete: Function; connectWithGoogle: Function}
    ] {
  const [mediaList, setMediaList] = useState<SocialMediaModel[] >([]);

  const getAll = (): void => socialMediaService.socialMediaList();
  const connect = (infoSocialMedia: SocialMediaType): void => socialMediaService.connect(infoSocialMedia);
  const deleteSocialMedia = (id: number): void => socialMediaService.deleteConnectionWithFacebook(id);
  const connectWithGoogle = (data: ConnectGoogleCredentials): void => socialMediaService.connectWithGoogle(data);

  useEffect(() => {
    const subscription: Subscription[] = [
      onEmit(socialMediaQuery.socialMedias$, (value) => setMediaList(value)),
    ];
    return (): void => {
      subscription.map((value) => value.unsubscribe());
    };
  }, []);
  return [{getAll, medias: mediaList, connect, delete: deleteSocialMedia, connectWithGoogle}];
}
