import {Http} from '../shared/http.class';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {API_CMS_URL} from '../shared/ConstantUrl';
import {SocialMediaType} from '../shared/types/socialMedia.type';
import {ConnectGoogleCredentials} from '../shared/customType';
import {GenericResponseType} from '../shared/types/genericResponse.type';
import {socialMediaStore} from '../stores/socialMedia.store';
import {SocialMediaModel} from '../models/socialMedia.model';
import {globalStore} from '../stores/global.store';

type ObsSocialMediaArray = Observable<GenericResponseType<SocialMediaModel[]>>
type ObsSocialMedia = Observable<GenericResponseType<SocialMediaModel>>

class SocialMediaService {
    private http: Http;

    constructor() {
      this.http = Http.httpGetInstance();
    }

    public socialMediaList(): void {
      this.http.baseUrl = API_CMS_URL;
      this.http.get$('social-media-fb').pipe(
          mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsSocialMediaArray ),
          tap((x) => socialMediaStore.set(x.data)),
          catchError((err) => this.http.addErrorStore(socialMediaStore, err)),
      ).subscribe();
    }

    public connect(infoSocialMedia: SocialMediaType): void {
      this.http.baseUrl = API_CMS_URL;
      this.http.post$('social-media-fb', infoSocialMedia).pipe(
          mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsSocialMedia),
          tap((x) => socialMediaStore.add(x.data)),
          catchError((err) => this.http.addErrorStore(socialMediaStore, err)),
      ).subscribe();
    }

    public deleteConnectionWithFacebook(id: number): void {
      this.http.baseUrl = API_CMS_URL;
      this.http.delete$('social-media-fb', id).pipe(
          mergeMap((value) => this.http.validateResponseOfRequest(value)),
          tap(() => socialMediaStore.remove(id)),
          catchError((err) => this.http.addErrorStore(socialMediaStore, err)),
      ).subscribe();
    }

    public connectWithGoogle(infoConnection: ConnectGoogleCredentials): void {
      this.http.baseUrl = API_CMS_URL;
      this.http.post$('youtube-refresh', infoConnection).pipe(
          mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsSocialMedia),
          tap((x) => socialMediaStore.add(x.data)),
          catchError((err) => this.http.addErrorStore(socialMediaStore, err)),
      ).subscribe();
    }

    public accessTokenYoutube(): void {
      this.http.baseUrl = API_CMS_URL;
      this.http.get$('google-oauth').pipe(
          mergeMap((value) => this.http.validateResponseOfRequest(value)),
          tap((x) => {
            if (x && x.data && x.data.access_token) {
              globalStore.update((prevState) => ({...prevState, tokenYoutubeActive: x.data.access_token}) );
            }
          }),
          catchError((err) => this.http.addErrorStore(globalStore, err)),
      ).subscribe();
    }
}

export const socialMediaService = new SocialMediaService();
