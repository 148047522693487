import React, {useEffect, useState} from 'react';
import {IonCheckbox, IonItem, IonLabel, IonList, IonSearchbar} from '@ionic/react';
import {CategoryModel} from '../../models/Category.model';
import Fuse from 'fuse.js';
import {whatever} from '../../shared/customType';
import {useCategoryFacade} from '../../hooks/facade/categoryFacade.hook';
import ListCategory from '../dummie/Category/ListCategory';

interface Props {
  height?: string;
  seletedCategories: Function;
  settedCategory?: CategoryModel[];
}

const Category: React.FC<Props> = ({seletedCategories, settedCategory, height}) => {
  const [{catState: {categories}}] = useCategoryFacade();
  const [reload, setReload] = useState<boolean>(false);
  const [origin, setOrigin] = useState<CategoryModel[]>(categories);
  const [eventText, setEventText] = useState<string>('');
  const [selected, setSelected] = useState<CategoryModel[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 0,
    tokenize: true,
    matchAllTokens: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name'],
  };
  const fuse = new Fuse(categories, options);

  useEffect(() => {
    setOrigin(categories);
  }, [categories]);

  useEffect(() => {
    if (settedCategory && categories && !selectedAll) {
      setSelected(settedCategory);
      settedCategory.forEach((value) => {
        categories.forEach((value1, index1) => {
          if (value.id === value1.id) {
            categories[index1] = {...value, isChecked: true};
            setReload(!reload);
          }
        });
      });
    }
    // eslint-disable-next-line
  }, [settedCategory, categories]);

  useEffect(() => {
    if (selected.length) seletedCategories(selected);
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    setOrigin(eventText ? fuse.search(eventText) : categories);
    // eslint-disable-next-line
  }, [eventText]);

  const selectUnselectAll = (event: boolean): void => {
    setSelectedAll(event);
    categories.forEach((value: CategoryModel, index) => categories[index] = {
      ...categories[index],
      isChecked: event,
    });
    setSelected(event ? categories : []);
  };

  const removeSelected = (category: CategoryModel): void => {
    const aux = selected.filter((value) => value.id !== category.id);
    setSelected(aux);
  };

  const addSelected = (category: CategoryModel): void => {
    const aux = selected.filter((value) => value.id === category.id);
    if (!aux.length) {
      setSelected((state) => state.concat(category));
    }
  };

  return (
    <>
      <IonSearchbar
        placeholder="Buscar"
        value={eventText}
        onIonChange={(e: whatever): void => setEventText(e.target.value)} />
      <IonList style={{height, overflow: 'auto'}}>
        <IonItem lines="none">
          <IonLabel >All</IonLabel>
          <IonCheckbox onIonChange={(event): void => selectUnselectAll(event.detail.checked)} slot={'end'}/>
        </IonItem>
        <ListCategory
          addCategory={(e: CategoryModel): void => addSelected(e)}
          removeCategory={(e: CategoryModel): void => removeSelected(e)}
          categories={origin} />
      </IonList>
    </>
  );
};

export default Category;
