import {postStore, PostStore} from '../stores/post.store';
import {ID} from '@datorama/akita';
import {Post} from '../models/post.model';
import {StatusPostEnum} from '../shared/types/enums/statusPostEnum';
import {Http} from '../shared/http.class';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {API_CMS_URL, DRAFT, POST} from '../shared/ConstantUrl';
import {Observable} from 'rxjs';
import {GenericResponseType, ResponseError} from '../shared/types/genericResponse.type';
import {globalService} from './global.service';

type ObsPostArray = Observable<GenericResponseType<Post[]>>
type ObsPost = Observable<GenericResponseType<Post>>

export class PostService {
  private http: Http;

  constructor(private postStore: PostStore) {
    this.http = Http.httpGetInstance();
  }
  public loadAll(length?: number, addPublish?: boolean): void {
    if (length) globalService.update({hasMorePost: true});
    this.http.baseUrl = API_CMS_URL;
    this.http.get$(`${POST}${length ? '?startAt=' +length : ''}`).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsPostArray),
        tap((x) => {
          if (!length && !addPublish ) {
            this.postStore.set(x.data);
          } else {
            this.addStora(x.data);
          }
          if (!x.data.length) {
            globalService.update({hasMorePost: false});
          }
          this.postStore.setLoading(true);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),
    ).subscribe();
  }

  public add(newPost: Post): ObsPost {
    this.postStore.set([]);
    newPost.mode = StatusPostEnum.DRAFT;
    this.http.baseUrl = API_CMS_URL;
    return this.http.post$(DRAFT, newPost).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsPost),
        tap((x) => {
          this.postStore.add(x.data);
          this.postStore.setLoading(true);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),
    );
  }

  public getByUrl(url: string): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.get$(`${POST}?url=${url}`).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsPostArray),
        tap((x) => {
          this.postStore.set(x.data);
          this.postStore.setActive(x.data[0].id);
          this.postStore.setLoading(true);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),

    ).subscribe();
  }

  public getByUser(): void {
    this.postStore.set([]);
    this.http.baseUrl = API_CMS_URL;
    this.http.get$(`${DRAFT}?user=true`).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsPostArray),
        tap((x) => {
          this.postStore.set(x.data);
          this.postStore.setLoading(true);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),
    ).subscribe();
  }

  public update(post: Post): ObsPost {
    this.postStore.setLoading(false);
    this.http.baseUrl = API_CMS_URL;
    return this.http.put$(`${DRAFT}/${post.id}`, post).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsPost),
        tap((x) => {
          this.updateActive(x.data);
          this.postStore.setLoading(true);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),
    );
  }

  public getReview(): ObsPostArray {
    this.http.baseUrl = API_CMS_URL;
    return this.http.get$(`${DRAFT}?review=true`).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsPostArray),
        tap((x) => {
          x.data.map((value1) => this.addStora(value1));
          this.postStore.setLoading(true);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),
    );
  }

  public addLike(id: ID): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.put$(`${POST}/${id}/like`, undefined).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value)),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),
    ).subscribe();
  }

  public delete(id: ID): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.delete$(DRAFT, id).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsPost),
        tap((response) => {
          this.removeActive(response.data.id as ID);
          this.removePost(response.data.id as ID);
        }),
        catchError((err: ResponseError) => this.http.addErrorStore(this.postStore, err)),
    ).subscribe();
  }

  public removeActive(id: ID): void {
    this.postStore.removeActive(id);
  }

  public setActive(id: ID): void {
    this.postStore.setActive(id);
  }

  public updateActive(post: Post): void {
    this.postStore.updateActive(post);
  }

  public addStora(post: Post[] | Post): void {
    this.postStore.add(post);
  }

  public removePost(id: ID): void{
    this.postStore.remove(id);
  }

  public reset(): void {
    this.postStore.set([]);
  }
}

export const postService = new PostService(postStore);
