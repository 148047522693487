import React, {useEffect, useState} from 'react';
import {IonCol, IonGrid, IonRow, IonChip} from '@ionic/react';
import {useCategoryFacade} from '../../../hooks/facade/categoryFacade.hook';
import {CategoryModel} from '../../../models/Category.model';
import Carousel, {CarouselProps, ResponsiveType} from 'react-multi-carousel';
import '../../../styles/Category.scss';

const configSlides: Partial<CarouselProps> = {
  draggable: true,
  ssr: false,
  renderDotsOutside: true,
  showDots: false,
  arrows: false,
  partialVisible: true,
};

const responsive: ResponsiveType = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 6,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 6,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 3.6,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 3.6,
  },
  mobileXS: {
    breakpoint: {max: 400, min: 0},
    items: 2.6,
  },
};

interface Props {
    returnedCategory?: Function;
    staticCategory?: CategoryModel[];
    settings?: ResponsiveType;
}

const MinimalismCategory: React.FC<Props> = ({returnedCategory, staticCategory, settings= configSlides}) => {
  const [{catState: {categories}}] = useCategoryFacade();
  const [display, setDisplay] = useState<CategoryModel[]>(staticCategory ? staticCategory : []);
  const [selected, setSelected] = useState('all');

  useEffect(() =>{
    if (categories.length) {
      categories.unshift({name: 'Todas', abbreviation: 'To', isChecked: false});
    }
    if (staticCategory?.length) staticCategory.unshift({name: 'Todas', abbreviation: 'To', isChecked: false});
    setTimeout(()=> {
      setDisplay(staticCategory ? staticCategory : categories);
    }, 400);
    // eslint-disable-next-line
    }, [staticCategory, categories]);
  return (
    <IonGrid>
      <IonRow>
        <IonCol style={{padding: '0 15px'}}
          className="categorySlide"
          sizeXl="5" offsetXl="3" sizeSm="12" sizeMd="8" offsetMd="2" offsetSm="0" sizeXs="12" offsetXs="0" >
          <Carousel responsive={responsive} {...settings}>
            {display.map((value, index) => (
              <div key={index} style={{width: '100%', textAlign: 'center'}}>
                <IonChip
                  className={selected === value.name ? 'categorySelected' : ''}
                  style={{alignSelf: 'center'}}
                  color="medium" onClick={(): void => {
                    setSelected(value.name);
                    if (returnedCategory) returnedCategory(value.name !== 'Todas' ? value : null);
                  }} >
                  <span>{value.name}</span>
                </IonChip>
              </div>
            ))}
          </Carousel>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default MinimalismCategory;
