import {Http} from '../shared/http.class';
import {userProfileStore, UserProfileStore} from '../stores/UserProfile.store';
import {API_AUTH_URL, API_CMS_URL, MY_PROFILE_URL, USER_PROFILE_URL} from '../shared/ConstantUrl';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {UserProfileType} from '../shared/types/UserProfile.type';
import {ID} from '@datorama/akita';
import {removeObject$} from '../shared/Storage';
import {PROFILE, TOKEN} from '../shared/ConstantDataStructure';
import {Observable, of} from 'rxjs';
import {GenericResponseType} from '../shared/types/genericResponse.type';

type ObsUserProArray = Observable<GenericResponseType<UserProfileType[]>>
type ObsUserPro = Observable<GenericResponseType<UserProfileType>>

export class UserProfileService {
  private http: Http;

  constructor(private userProfileStore: UserProfileStore) {
    this.http = Http.httpGetInstance();
  }

  getProfile(): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.get$(MY_PROFILE_URL).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsUserPro),
        tap((x) => {
          this.userProfileStore.set([x.data]);
          this.userProfileStore.setActive(x.data.id);
        }),
        catchError((err) => this.http.addErrorStore(this.userProfileStore, err)),
    ).subscribe();
  }

  getListProfile(): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.get$(USER_PROFILE_URL).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as ObsUserProArray),
        tap((x) => {
          this.userProfileStore.set(x.data);
        }),
        catchError((err) => this.http.addErrorStore(this.userProfileStore, err)),
    ).subscribe();
  }

  setLoadedProfile(profile: UserProfileType): void {
    this.userProfileStore.set([profile]);
  }

  updateProfile(profile: UserProfileType): void {
    this.http.baseUrl = API_CMS_URL;
    this.http.put$(MY_PROFILE_URL, profile).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value)),
        tap((x) => {
          if (x.data.id) {
            this.userProfileStore.update(x.data.id, {role: x.data.role});
          }
        }),
        // catchError((err) => this.http.addErrorStore(this.userProfileStore, err)),
    ).subscribe();
  }

  renewToken(): void {
    this.http.baseUrl = API_AUTH_URL;
    this.http.get$('renew').pipe(
        mergeMap((value) => value.json()),
        tap((x) => {
          localStorage.setItem('_cap_token', JSON.stringify(x.map));
        }),
        catchError((err) => {
          removeObject$([TOKEN, PROFILE]);
          window.location.href = '/';
          return of(err);
        }),
    ).subscribe();
  }

  setActive(id: ID): void {
    this.userProfileStore.setActive(id);
  }

  removeActive(): void {
    this.userProfileStore.setActive([]);
  }

  removeAll(): void {
    this.userProfileStore.reset();
  }
}

export const userProfileService = new UserProfileService(userProfileStore);
