import {Observable, of, throwError} from 'rxjs';
import {RxJSHttpClient} from 'rxjs-http-client';
import {IHttpResponse} from 'rxjs-http-client/types/http-response.interface';
import {whatever} from './customType';
import {TokenModel} from '../models/token.model';
import {EntityStore, Store, ID} from '@datorama/akita';
import {ResponseError} from './types/genericResponse.type';
import {globalService} from '../services/global.service';

export class Http {
    public static httpInstance: Http;
    private _http: RxJSHttpClient;
    private _baseUrl: string;
    private _token = '';
    private _headers: { [headerName: string]: string };

    private constructor() {
      this._http = new RxJSHttpClient();
      this._headers = {};
      this._baseUrl = '';
      this.initializeToken();
    }

    public initializeToken(): void {
      const x: TokenModel | null = JSON.parse(localStorage.getItem('_cap_token') as string);
      this._token = x?.token ? x.token : '';
      this._headers = {
        Authorization: 'Bearer ' + this._token,
      };
    }

    public static httpGetInstance(): Http {
      this.httpInstance = new Http();
      this.httpInstance.initializeToken();
      return this.httpInstance;
    }

    public get$(url: string): Observable<IHttpResponse> {
      this.initializeToken();
      return this._http.get(
          `${this._baseUrl}/${url}`,
          {headers: this._headers},
      );
    }

    public post$(url: string, data: whatever): Observable<IHttpResponse> {
      this.initializeToken();
      return this._http.post(
          `${this._baseUrl}/${url}`,
          {body: data, headers: this._headers},
      );
    }

    public put$(url: string, data?: whatever, id: ID = ''): Observable<IHttpResponse> {
      this.initializeToken();
      return this._http.put(
          `${this._baseUrl}/${url}${id !== '' ? '/' : id}${id}`,
          {body: data, headers: this._headers},
      );
    }

    public delete$(url: string, idResource: ID): Observable<IHttpResponse> {
      this.initializeToken();
      return this._http.delete(
          `${this._baseUrl}/${url}/${idResource}`,
          {headers: this._headers},
      );
    }

    public validateResponseOfRequest(response: IHttpResponse): Observable<whatever> {
      if (response.status === 200 || response.status === 201) {
        return response.json();
      }
      const genericError: ResponseError = {
        data: undefined,
        flagResult: false,
        message: response.statusText,
        code: response.status,
      };
      return throwError(genericError);
    }

    public addErrorStore<T extends EntityStore | Store>(store: T, error: ResponseError): Observable<ResponseError> {
      store.setError(error);
      globalService.updateError(error);
      store.setLoading(true);
      setTimeout(() => {
        globalService.updateError({...error, code: 0, flagResult: true});
        store.setError(null);
      }, 2500);
      return of(error);
    }

    public set baseUrl(value: string) {
      this._baseUrl = value;
    }

    public set token(value: string) {
      this._token = value;
    }
}
