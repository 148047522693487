import React, {CSSProperties, useEffect, useState} from 'react';

import '../../../styles/Home.scss';
import {Post} from '../../../models/post.model';
import Img from 'react-image';
import {URL_IMAGE_SMALL} from '../../../shared/ConstantUrl';
import {
  IonCard,
  IonCardHeader,
  IonCardTitle, IonCol,
  IonGrid,
  IonLabel, IonRow,
  IonSkeletonText,
  IonThumbnail,
} from '@ionic/react';
import {Link} from 'react-router-dom';
import Carousel, {CarouselProps, ResponsiveType} from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const styles: CSSProperties = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
};

const configSlides: Partial<CarouselProps> = {
  draggable: true,
  itemClass: 'carousel-item-padding-40-px',
  ssr: false,
  renderDotsOutside: true,
  showDots: false,
  arrows: false,
};

const responsive: ResponsiveType = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5.3,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3.3,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2.3,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 2.3,
  },
};

interface Props {
    posts: Post[];
    settings?: ResponsiveType;
    titleSlide: string;
}

const SlidePost: React.FC<Props> = ({settings = configSlides, posts, titleSlide}) => {
  const [displayPost, setDisplayPost] = useState<Post[]>([]);

  useEffect(() => {
    setTimeout(()=> {
      setDisplayPost(posts);
    }, 50);
  }, [posts]);

  return (
    <IonGrid>
      <IonRow >
        <IonCol style={{padding: '0 15px'}}
          sizeXl="5" offsetXl="3" sizeSm="5" sizeMd="8" offsetMd="2" offsetSm="3" sizeXs="12" offsetXs="0" >
          <IonCard className="animated fadeInDown delay-100ms">
            <div style={styles}>
              <Carousel responsive={responsive} {...settings}>
                {displayPost.map((value) =>
                  <div key={value.id} >
                    <Link to={`/post/${value.postUrl}`}>
                      <Img
                        className="post-slide-image"
                        alt={value.title}
                        src={value.mediaUrl && !value.youtubeId ? `${URL_IMAGE_SMALL}${value.mediaUrl}`:
                            value.youtubeId ? value.mediaUrl :
                            'assets/image/noImage.jpg'}
                        loader={ <IonThumbnail style={{width: '100%', height: '120px'}} slot="start">
                          <IonSkeletonText animated/>
                        </IonThumbnail>}
                      />
                      <div className="content-slide-title">
                        <label className="title-slide-post">{value.title}</label>
                      </div>
                    </Link>
                  </div>,
                )}
              </Carousel>
            </div>
            <IonCardHeader>
              <IonCardTitle style={{marginTop: '0px'}}>
                <IonLabel>{titleSlide}</IonLabel>
              </IonCardTitle>
            </IonCardHeader>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default SlidePost;
