import React from 'react';
import {IonToast} from '@ionic/react';
import {ResponseError} from '../../../shared/types/genericResponse.type';
import {ERROR_HTTP} from '../../../shared/ConstantLabel';

interface Props {
  isOpen: ResponseError;
  message?: string;
}

const ToastError: React.FC<Props> = ({isOpen, message}) => {
  return (
    <>
      <IonToast
        isOpen={isOpen && !isOpen?.flagResult}
        message={message ? message : ERROR_HTTP}
        duration={message ? 4200 : 2500}
        position="top"
        color={message ? 'danger' : 'dark'}
      />
    </>
  );
};
export default ToastError;

