import React, {useEffect, useState} from 'react';
import {IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonIcon, IonPage, IonRow} from '@ionic/react';
import {RawDraftContentState} from 'draft-js';

import Splash from '../dummie/editor/MediaEditor/splash';
import TitleEditor from '../dummie/editor/TitleEditor/TitleEditor';
import EditorText from '../dummie/editor/TextEditor/Editor';
import {usePostFacade} from '../../hooks/facade/postFacade.hook';
import {save} from 'ionicons/icons';
import UploadMedia from '../dummie/UploadMedia/UploadMedia';
import {Media} from '../../shared/types/media.type';
import {URL_IMAGE_MEDIUM} from '../../shared/ConstantUrl';
import {RouteComponentProps} from 'react-router';
import VideoDisplayer from '../dummie/Youtube/VideoDisplayer';
import {whatever} from '../../shared/customType';
import Principal from '../dummie/Youtube/Principal';
import {AlertModal} from '../dummie/Modal/AlertModal';
import Loader from '../dummie/Loader/loader';

const EditPost: React.FC<RouteComponentProps<{urlPost: string}>> = ({match, history}) => {
  const [pathUrlMedia, setPathUrlMedia] = useState(`${URL_IMAGE_MEDIUM}`);
  const [{state: {active}, updateActive, updatePost, searchByUrl, loadingStatePost}] = usePostFacade();
  const [modalYoutube, setModalYoutube] = useState(false);

  useEffect(() => {
    if (match.path === '/edit-post/:urlPost') {
      if (active && active.mediaUrl && active.mediaUrl.includes('base64')) {
        setPathUrlMedia('');
      } else {
        setPathUrlMedia(`${URL_IMAGE_MEDIUM}`);
      }
    }
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    if (! active) {
      searchByUrl(match.params.urlPost);
    }
    // eslint-disable-next-line
    }, [match.params.urlPost] );

  return (
    <IonPage>
      <Loader isLoader={loadingStatePost} history={history} />
      <IonContent className="ion-padding">
        {active ?
        <IonGrid>
          <IonRow>
            <IonCol
              style={{height: '65px', paddingLeft: '15px'}}
              sizeXl="8" offsetXl="2"
              sizeSm="10"
              offsetSm="1"
              sizeXs="12"
              offsetXs="0">
              <UploadMedia onMedia={(media: Media): void => {
                updateActive({...active, mediaUrl: media.base64, youtubeId: ''});
              }}
              loadYoutube={(e: boolean): void => setModalYoutube(e)} />
            </IonCol>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              {active.mediaUrl && !active?.youtubeId ?
                <Splash splashMedia={`${pathUrlMedia+active.mediaUrl}`} />:
              active.youtubeId ? <VideoDisplayer url={active.youtubeId} />:
                  <Splash splashMedia='assets/image/noImage.jpg'/>}
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <TitleEditor
                title={active.title}
                OnChangeTitle={(title: string): void => updateActive({...active, title})}/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <EditorText
                onEditorChange={(editor: RawDraftContentState): void =>
                  updateActive({...active, description: JSON.stringify(editor)})}
                editor={active?.description ? JSON.parse(active.description) : undefined}
                toolbarOnFocus={false}
              />
            </IonCol>
          </IonRow>
        </IonGrid>: null}
      </IonContent>
      <IonRow>
        <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
          <IonFab horizontal="end" vertical="bottom">
            <IonFabButton color="second" disabled={!loadingStatePost} onClick={(): void => {
              updatePost(active);
            }}>
              <IonIcon icon={save} />
            </IonFabButton>
          </IonFab>
        </IonCol>
      </IonRow>
      <AlertModal isOpen={modalYoutube} dismiss={(e: whatever): void => setModalYoutube(e)} title="Youtube Video">
        <Principal selectedVideo={(e: string): void => {
          if (e) {
            setModalYoutube(false);
            updateActive({...active, youtubeId: e});
          }
        }}/>
      </AlertModal>
    </IonPage>
  );
};
export default EditPost;
