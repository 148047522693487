import {QueryEntity} from '@datorama/akita';
import {UserProfileState, userProfileStore} from '../stores/UserProfile.store';
import {UserProfileType} from '../shared/types/UserProfile.type';
import {Observable} from 'rxjs';

export class UserProfileQuery extends QueryEntity<UserProfileState, UserProfileType> {
  userProfile$: Observable<UserProfileType[]> = this.selectAll();
  activeUserProfile$: Observable<UserProfileType> = this.selectActive<UserProfileType>() as Observable<UserProfileType>;
}

export const userProfileQuery = new UserProfileQuery(userProfileStore);
