import {QueryEntity} from '@datorama/akita';
import {PostSate, postStore} from '../stores/post.store';
import {Post} from '../models/post.model';
import {Observable} from 'rxjs';
import {ResponseError} from '../shared/types/genericResponse.type';

export class PostQuery extends QueryEntity<PostSate, Post> {
    posts$: Observable<Post[]> = this.selectAll();
    activePost$: Observable<Post> = this.selectActive<Post>() as Observable<Post>;
    loading$: Observable<boolean> = this.selectLoading();
    error$: Observable<ResponseError> = this.selectError();
}

export const postQuery = new PostQuery(postStore);
