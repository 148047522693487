import React, {useEffect, useState} from 'react';
import {IonCol, IonContent, IonGrid, IonPage, IonRow} from '@ionic/react';
import PostListStatusSegment from '../dummie/post/PostListStatusSegment';
import {StatusPostEnum} from '../../shared/types/enums/statusPostEnum';
import {usePostFacade} from '../../hooks/facade/postFacade.hook';
import PostList from '../dummie/post/PostList';
import {RouteComponentProps} from 'react-router';
import {Post} from '../../models/post.model';
import GenericPopOver from '../dummie/PopOver/GenericPopOver';
import ConfigOptionPost from '../dummie/post/ConfigOptionPost';
import {prfEnum} from '../../shared/types/enums/prfEnum';
import {UserProfileType} from '../../shared/types/UserProfile.type';
import {AlertModal} from '../dummie/Modal/AlertModal';
import ConfigPostPublish from '../dummie/post/ConfigPostPublish';
import {ConfigPostType} from '../../shared/types/ConfigPost.type';
import Loader from '../dummie/Loader/loader';

const Profile: React.FC<RouteComponentProps> = ({history}) => {
  const [
    {state: {active, posts}, searchByUser, setActive, updatePost, getReview, loadingStatePost, loadAll, deletePost},
  ] = usePostFacade();
  const user = JSON.parse(localStorage.getItem('_cap_usr_prof') as string) as UserProfileType;
  useEffect(() => {
    if (history.location.pathname === '/profile/') {
      searchByUser();
      if (user && (user.role === prfEnum.ADM || user.role === prfEnum.PBLSHR)) {
        getReview();
        loadAll(undefined, true);
      }
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  const [statusListPost, setStatusListPost] = useState(Object.keys(StatusPostEnum)[0] as StatusPostEnum);
  const [openPopOver, setOpenPopOver] = useState<boolean>(false);
  const [propEvent, setPropEvent] = useState();
  const [openModalConfigPublishPost, setOpenModalConfigPublishPost] = useState(false);

  const selectionPost = (object: {event: React.MouseEvent; post: Post}): void => {
    setActive(object.post.id);
    setOpenPopOver(true);
    object.event.persist();
    setPropEvent(object.event);
  };

  const configPost = (config: ConfigPostType): void => {
    updatePost({...active, ...config, mode: user && (user.role === prfEnum.ADM || user.role === prfEnum.PBLSHR) ?
          StatusPostEnum.PUBLISHED : StatusPostEnum.REVIEW});
    setOpenModalConfigPublishPost(false);
  };
  return (
    <IonPage>
      <Loader isLoader={loadingStatePost} history={history}/>
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <h1>Mis Post</h1>
              <PostListStatusSegment
                onStatus={(status: StatusPostEnum): void => setStatusListPost(status)}
                defaultValue={statusListPost}
              />
            </IonCol>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <PostList
                postsList={posts.sort((a, b) =>
                  new Date(b.creationDate as Date).getTime() - new Date(a.creationDate as Date).getTime())}
                filter={statusListPost} onSelectPost={selectionPost}
              />
            </IonCol>
          </IonRow>
          <br/>
        </IonGrid>
        {active ?
            <GenericPopOver open={openPopOver} onClose={(): void => setOpenPopOver(false)} event={propEvent}>
              <ConfigOptionPost
                post={active}
                rolUser={user && user.role ? user.role : ''}
                onSelectOption={(): void => setOpenPopOver(false)}
                onSelectOptionReview={(post: Post): void => updatePost(post)}
                onMoveToDraft={(post: Post): void => updatePost(post)}
                onSelectEditConfig={(): void => setOpenModalConfigPublishPost(true)}
                onSelectOptionPublish={(): void => setOpenModalConfigPublishPost(true)}
                onSelectDelete={(post: Post): void => deletePost(post.id)}
              />
            </GenericPopOver> : null}
        <AlertModal
          title="Configurar publicacion"
          isOpen={openModalConfigPublishPost}
          dismiss={(): void => setOpenModalConfigPublishPost(false)}
        >
          <ConfigPostPublish post={active as Post} onConfig={configPost}/>
        </AlertModal>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
