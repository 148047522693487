/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {CategoryModel} from '../../models/Category.model';
import {useEffect, useState} from 'react';
import {categoryService} from '../../services/category.service';
import {onEmit} from './postFacade.hook';
import {categoryQuery} from '../../query/category.query';
import {Subscription} from 'rxjs';
import {ResponseError} from '../../shared/types/genericResponse.type';

interface CategoryState {
  categories: CategoryModel[];
}

export function useCategoryFacade(): [
    {catState: CategoryState; add: Function; getAll: Function; errorStateCategory: ResponseError; remove: Function}
    ] {
  const [catState, setCatState] = useState<CategoryState>({categories: []});
  const [error, setError] = useState();

  const add = (category: CategoryModel): void => categoryService.add(category);
  const getAll = (): void => categoryService.getAll();
  const remove = (category: CategoryModel): void => categoryService.remove(category.id as number);

  useEffect(() => {
    const subscribedCategory: Subscription[] = [
      onEmit<CategoryModel[]>(categoryQuery.category$, (categories) => {
        categories.forEach((value: CategoryModel, index) => categories[index] = {...value, isChecked: false} );
        setCatState((state) => ({...state, categories}));
      }),
      onEmit(categoryQuery.error$, (value) => setError(value)),
    ];

    return () => {
      subscribedCategory.map((value) => value.unsubscribe());
    };
    // eslint-disable-next-line
  }, []);

  return [{catState, add, getAll, errorStateCategory: error, remove}];
}
