import React, {useEffect, useState} from 'react';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRow,
  IonTextarea,
} from '@ionic/react';
import {whatever} from '../../../../shared/customType';
import MediaUploader from '../../../../shared/apiAdapter/cors_upload';
import {onCompleteUpload, onErrorUpload, onProgressUpload} from '../../../../shared/apiAdapter/uploadYoutubeApi';
import {API_YOUTUBE} from '../../../../shared/ConstantUrl';
import {useSocialMediaFacade} from '../../../../hooks/facade/socialMediaFacade.hook';
import {useGlobalFacade} from '../../../../hooks/facade/globalFacade.hook';

interface Props {
  selected: Function;
}

const UploadVideoYoutube: React.FC<Props> = ({selected}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [{medias, getAll}] = useSocialMediaFacade();
  const [{tokenYoutubeActive, accessYoutubeToken}] = useGlobalFacade();
  const [percentageLoad, setPercentageLoad] = useState(0);
  useEffect(() => {
    getAll();
    accessYoutubeToken();
    // eslint-disable-next-line
  }, []);


  const uploadVideo = (): void => {
    const files = document.getElementById('upload_video') as whatever;
    if (files.files[0]) {
      const metadata = {
        snippet: {
          title: title,
          description: description,
          tags: ['youtube-cors-upload'],
          categoryId: 22,
        },
        status: {
          privacyStatus: 'unlisted',
        },
      };
      const uploader = new MediaUploader({
        file: files.files[0],
        // eslint-disable-next-line max-len
        token: tokenYoutubeActive,
        baseUrl: API_YOUTUBE,
        metadata: metadata,
        params: {
          part: Object.keys(metadata).join(','),
        },
        onError: (data: whatever): void => onErrorUpload(data),
        onProgress: (data: whatever): void => {
          setPercentageLoad(onProgressUpload(data));
        },
        onComplete: (data: whatever): void => {
          selected(onCompleteUpload(data));
        },
      });
      uploader.upload();
    }
  };

  return (
    <IonGrid>
      {medias.length && medias.filter((value) => value.typeResource === 'youtube').length ?
          <>
            <IonRow>
              <IonCol>
                <IonList>
                  <IonItem>
                    <IonLabel position="floating">Titulo</IonLabel>
                    <IonInput value={title} onIonChange={(event): void => setTitle(event.detail.value as string)} />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Descripcion</IonLabel>
                    <IonTextarea
                      value={description}
                      onIonChange={(event): void => setDescription(event.detail.value as string)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Video</IonLabel>
                    <input type="file" id="upload_video" accept="video/*"/>
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
            <IonProgressBar value={percentageLoad}/>
            <IonRow>
              <IonCol style={{textAlign: 'end'}}>
                <IonButton color="light" onClick={uploadVideo}>Subir video</IonButton>
              </IonCol>
            </IonRow>
          </>:
          <IonRow>
            <IonCol style={{textAlign: 'center'}}>
              <br/>
              <IonLabel>Primero debe ingresar con su cuenta de Google</IonLabel>
              <br/>
              <IonLabel>en configuraciones</IonLabel>
            </IonCol>
          </IonRow>
      }
    </IonGrid>
  );
};
export default UploadVideoYoutube;
