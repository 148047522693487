/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {IonItem, IonLabel, IonRadio} from '@ionic/react';
import React from 'react';
import {ANIMATION_FADE_IN_LEFT, ANIMATION_FADE_IN_RIGHT} from './ConstantDataStructure';
function randomKey<T, K extends keyof T>(enumClass: T, key: K) {
  return (
    <IonItem key={key as string} lines="none">
      <IonLabel className={ANIMATION_FADE_IN_LEFT}>{enumClass[key as keyof typeof enumClass]}</IonLabel>
      <div className={ANIMATION_FADE_IN_RIGHT}><IonRadio slot="end" value={key} /></div>
    </IonItem>
    // <IonSelectOption key={key as string} value={key}>
    //   {enumClass[key]}
    // </IonSelectOption>
  );
}

export function createRadioEnum<T>(enumClass: T) {
  return Object.keys(enumClass).map((key) => randomKey(enumClass, key as keyof T));
}
