import {Query} from '@datorama/akita';
import {ConfigurationState, configurationStore, ConfigurationStore} from '../stores/configuration.store';
import {Observable} from 'rxjs';
import {ResponseError} from '../shared/types/genericResponse.type';

export class ConfigurationQuery extends Query<ConfigurationState> {
  public logo$ = this.select('logo');
  public favicon$ = this.select('favicon');
  public idDomain$ = this.select('id');
  public change$ = this.select('change');
  public value = this.getValue();
  public error$: Observable<ResponseError> = this.selectError();

  constructor(protected store: ConfigurationStore) {
    super(store);
  }
}

export const configurationQuery = new ConfigurationQuery(configurationStore);
