import {QueryEntity} from '@datorama/akita';
import {CommentState, commentStore} from '../stores/comment.store';
import {CommentType} from '../shared/types/comment.type';
import {Observable} from 'rxjs';
import {ResponseError} from '../shared/types/genericResponse.type';

export class CommentQuery extends QueryEntity<CommentState, CommentType> {
    public comment$: Observable<CommentType[]> = this.selectAll();
    public activeComment$: Observable<CommentType> = this.selectActive<CommentType>() as Observable<CommentType>
    public loading$: Observable<boolean> = this.selectLoading();
    public error$: Observable<ResponseError> = this.selectError();
}

export const commentQuery = new CommentQuery(commentStore);
