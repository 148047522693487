import React from 'react';
import {IonLabel} from '@ionic/react';

interface Props {
    chanel: string;
    userName: string;
}

const LabelMedia: React.FC<Props> = ({chanel, userName}) => {
  return (
    <div style={{marginLeft: '5px'}}>
      <IonLabel>
        <h2>{userName}</h2>
        <h3 style={{color: '#3880ff'}}>{chanel}</h3>
      </IonLabel>
    </div>
  );
};

export default LabelMedia;
