import React, {useEffect, useState} from 'react';
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSearchbar,
  IonToolbar,
  IonIcon,
  IonFab,
  IonFabButton,
  IonBadge,
} from '@ionic/react';
import {useCategoryFacade} from '../../../hooks/facade/categoryFacade.hook';
import {CategoryModel} from '../../../models/Category.model';
import {whatever} from '../../../shared/customType';
import ListCategory from '../../dummie/Category/ListCategory';
import Fuse from 'fuse.js';
import {add, trash} from 'ionicons/icons';
import '../../../styles/Category.scss';
import {ANIMATION_FADE_IN_RIGHT} from '../../../shared/ConstantDataStructure';
import ToastError from '../../dummie/TostError/ToastError';
import {CATEGORY_NOT_DELETED} from '../../../shared/ConstantLabel';

const Category: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [{catState: {categories}, add: newCategory, remove, errorStateCategory}] = useCategoryFacade();
  const [category, setCategory] = useState<CategoryModel[]>(categories);
  const [toRemove, setToRemove] = useState<CategoryModel[]>([]);
  const [addButton, setAddButton] = useState<boolean>(false);
  const options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 0,
    tokenize: true,
    matchAllTokens: true,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name'],
  };
  const fuse = new Fuse(categories, options);

  useEffect(()=> {
    setCategory(categories);
    // eslint-disable-next-line
  }, [categories]);

  useEffect((): void =>{
    setCategory(inputValue ? fuse.search(inputValue) : categories);
    if (!inputValue) setAddButton(false);
    if (!category.filter((value) => value.name.toLocaleLowerCase() === inputValue.toLocaleLowerCase()).length &&
    inputValue.trim()) {
      setAddButton(true);
    } else {
      setAddButton(false);
    }
    // eslint-disable-next-line
  }, [inputValue]);

  const handleSumbit = (): void => {
    if (inputValue.trim()) {
      newCategory({name: inputValue, abbreviation: inputValue.substring(0, 2), isChecked: false});
      setInputValue('');
    }
  };

  const addCategory =(e: CategoryModel): void => {
    let aux: CategoryModel[] = [];
    if (toRemove.length) aux = toRemove.filter((value: { name: string }) => value.name === e.name);

    if (e.isChecked && !aux.length) {
      setToRemove(
          [...toRemove,
            {isChecked: e.isChecked, name: e.name, picNumb: e.picNumb, abbreviation: e.abbreviation, id: e.id}]);
    }
  };

  const removeCategory = (e: CategoryModel): void => {
    if (toRemove.length) setToRemove(toRemove.filter((value) => value.name !== e.name));
  };

  const onRemoveSelectedCategory = (): void => {
    if (toRemove.length) {
      toRemove.forEach((value) => {
        remove(value);
      });
      setToRemove([]);
    }
  };

  return (
    <>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <form onSubmit={(e): void => {
                e.preventDefault();
                handleSumbit();
              }}>
                <IonToolbar>
                  <IonGrid>
                    <IonRow style={{height: '68px'}}>
                      <IonCol sizeMd="10" offsetMd="0" sizeSm="10" offsetSm="0" sizeXs="10" offsetXs="0">
                        <IonSearchbar
                          placeholder="Buscar/Agregar"
                          value={inputValue}
                          animated={true}
                          debounce={500}
                          onIonChange={(e: whatever): void => {
                            setInputValue(e.detail.value as string);
                          }} />
                      </IonCol>
                      <IonCol sizeMd="2" offsetMd="0" sizeSm="2" offsetSm="0">
                        {toRemove.length ?
                            <IonFab className={ANIMATION_FADE_IN_RIGHT}>
                              <IonBadge color="light" className="deleteBadge">{toRemove.length}</IonBadge>
                              <IonFabButton size="small" onClick={(): void => onRemoveSelectedCategory()}>
                                <IonIcon icon={trash}/>
                              </IonFabButton>
                            </IonFab> :
                            addButton && !toRemove.length ?
                                <IonFab className={ANIMATION_FADE_IN_RIGHT}>
                                  <IonFabButton size="small" type="submit" onClick={(): void => handleSumbit()}>
                                    <IonIcon icon={add}/>
                                  </IonFabButton>
                                </IonFab> :
                                null}
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonToolbar>
              </form>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="categoryListContent">
              <div >
                <ListCategory
                  categories={category}
                  addCategory={(e: CategoryModel): void => addCategory(e)}
                  removeCategory={(e: CategoryModel): void => removeCategory(e)}/>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <ToastError isOpen={errorStateCategory} message={CATEGORY_NOT_DELETED} />
      </IonContent>
    </>
  );
};

export default Category;
