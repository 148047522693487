import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from '@ionic/react';
import Splash from '../dummie/editor/MediaEditor/splash';
import EditorText from '../dummie/editor/TextEditor/Editor';
import {RawDraftContentState} from 'draft-js';

import '../dummie/editor/TitleEditor/TitleEditor.css';
import {usePostFacade} from '../../hooks/facade/postFacade.hook';
import {URL_IMAGE_MEDIUM} from '../../shared/ConstantUrl';

import '../../styles/ViewPost.css';
import Like from '../dummie/Like/Like';
import {StatusPostEnum} from '../../shared/types/enums/statusPostEnum';
import {UserProfileType} from '../../shared/types/UserProfile.type';
import {Link} from 'react-router-dom';
import VideoDisplayer from '../dummie/Youtube/VideoDisplayer';

type Props = RouteComponentProps<{urlPost: string}>

const PostView: React.FC<Props> = ({match}) => {
  const [{state: {active}, searchByUrl, addLikePost}] = usePostFacade();
  const [like, setLike] = useState<number>(0);
  const user = JSON.parse(localStorage.getItem('_cap_usr_prof') as string) as UserProfileType;

  useEffect(() => {
    if (! active) {
      searchByUrl(match.params.urlPost);
    }
    // eslint-disable-next-line
  }, [match.params.urlPost] );

  useEffect(() => {
    setLike(active?.like as number);
    // eslint-disable-next-line
  }, [active]);

  const addLike = (): void => {
    setLike(like + 1);
    addLikePost(active?.id);
  };

  return (
    <IonPage>
      <IonContent className="ion-padding">
        {active ?
        <IonGrid>
          <IonRow >
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              {active.mediaUrl && !active?.youtubeId ?
                <Splash splashMedia={`${URL_IMAGE_MEDIUM}${active.mediaUrl}`} /> :
                active.youtubeId ? <VideoDisplayer url={active.youtubeId} />:
                    <Splash splashMedia='assets/image/noImage.jpg' />}
            </IonCol>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <h1 className="title-presentation">{active.title}</h1>
            </IonCol>
            <IonCol style={{display: 'flex'}} sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <h6 className="title-author">by {active.author ? active.author.name : ''}</h6>
            </IonCol>
            <IonCol style={{display: 'flex'}} sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <Like
                likeCount={like}
                onLikeCount={(): void => addLike()}
                disable={active.mode !== StatusPostEnum.PUBLISHED || user?.id === active?.author?.id}
              />
            </IonCol>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="10" offsetSm="1" sizeXs="12" offsetXs="0">
              <EditorText
                readOnly
                editor={active.description ? JSON.parse(active.description) as RawDraftContentState: undefined}/>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeXl="8" offsetXl="2" sizeSm="6" offsetSm="3" sizeXs="12" offsetXs="0">
              <Link to={`/comment/${active?.postUrl}`}>
                <IonButton expand="block">Ver Comentarios</IonButton>
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid> :
      null}
      </IonContent>
    </IonPage>
  );
};

export default PostView;
