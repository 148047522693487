export enum prfEnum {
  ADM = 'ADMIN',
  USR = 'USER',
  PBLSHR = 'PUBLISHER',
}

export enum roleUser {
  ADMIN = 'Administrador',
  USER = 'Usuario',
  PUBLISHER = 'Aprobador',
}
