import {commentStore, CommentStore} from '../stores/comment.store';
import {ID} from '@datorama/akita';
import {Observable} from 'rxjs';
import {Post} from '../models/post.model';
import {API_CMS_URL, DRAFT, POST} from '../shared/ConstantUrl';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {Http} from '../shared/http.class';
import {CommentType} from '../shared/types/comment.type';
import {GenericResponseType} from '../shared/types/genericResponse.type';

export class CommentService {
  private http: Http;

  constructor(private commentStore: CommentStore) {
    this.http = Http.httpGetInstance();
  }

  public getPostWithComment(id: ID): Observable<GenericResponseType<Post>> {
    this.commentStore.set([]);
    this.http.baseUrl = API_CMS_URL;
    return this.http.get$(`${DRAFT}/${id}`).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as Observable<GenericResponseType<Post>>),
        tap((x) => {
          this.commentStore.set(x.data.comments as CommentType[]);
          this.commentStore.setLoading(true);
        }),
        catchError((err) => this.http.addErrorStore(this.commentStore, err)),
    );
  }

  public addComment(comment: CommentType, postId: ID): Observable<GenericResponseType<CommentType>> {
    this.commentStore.setLoading(false);
    this.http.baseUrl = API_CMS_URL;
    return this.http.post$(`${POST}/${postId}/comment`, comment).pipe(
        mergeMap((value) => this.http.validateResponseOfRequest(value) as Observable<GenericResponseType<CommentType>>),
        tap((x) => {
          this.commentStore.add(x.data);
          this.commentStore.setLoading(true);
        }),
        catchError((err) => this.http.addErrorStore(this.commentStore, err)),
    );
  }
}

export const commentService = new CommentService(commentStore);
