import React, {ChangeEvent} from 'react';
import {IonIcon} from '@ionic/react';
import {image} from 'ionicons/icons';
import {MediaType} from '../../../../shared/types/enums/MediaType';
import {Media} from '../../../../shared/types/media.type';
import {INPUT_TYPE_FILE} from '../../../../shared/ConstantLabel';

interface Props {
    onFile: Function;
    multiple: boolean;
    id?: string;
}

const InputFile: React.FC<Props> = ({onFile, multiple, id = 'upload'}) => {
  const handleChangeEvent = (e: ChangeEvent<HTMLInputElement>): void => {
    e.persist();
    const files = e.target.files;
    if (files && files.length) {
      const allFiles: Media[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const {name} = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          allFiles.push({base64: reader.result as string, mediaName: name, mediaType: MediaType.IMAGE});
          if (allFiles.length === files.length) {
            onFile(allFiles);
          }
        };
      }
    }
  };

  return (
    <>
      <label htmlFor={id} style={{fontSize: '15pt', padding: '20px'}}>
        <IonIcon icon={image} />
      </label>
      <input
        style={{position: 'absolute', bottom: '-100000000px'}}
        id={id}
        type="file"
        accept={INPUT_TYPE_FILE}
        multiple={multiple}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          handleChangeEvent(e);
        }}
      />
    </>
  );
};

export default InputFile;
