/* eslint-disable @typescript-eslint/no-useless-constructor */
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {SocialMediaModel} from '../models/socialMedia.model';


export type SocialMediaState = EntityState<SocialMediaModel>

@StoreConfig({name: 'socialMedia'})
export class SocialMediaStore extends EntityStore<SocialMediaState, SocialMediaModel> {
  constructor() {
    super();
  }
}

export const socialMediaStore = new SocialMediaStore();
