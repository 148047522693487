import React, {useEffect, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonGrid,
  IonCol,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonLabel, IonFab, IonFabButton, IonIcon, IonFabList,
} from '@ionic/react';
import {useUserProfileFacade} from '../../../hooks/facade/userProfileFacade.hook';
import {UserProfileType} from '../../../shared/types/UserProfile.type';
import {RouteComponentProps} from 'react-router';
import UserList from './UserList';
import ConnectAccount from '../ConnectAccount/ConnectAccount';
import '../../../styles/Settings.scss';
import {
  ANIMATION_BOUNCE_IN_DOWN_RIGHT,
  ANIMATION_SLIDE_IN_LEFT,
  ANIMATION_SLIDE_IN_RIGHT, PROFILE,
} from '../../../shared/ConstantDataStructure';
import {setObject} from '../../../shared/Storage';
import {bookmark, easel, settings} from 'ionicons/icons';
import {AlertModal} from '../../dummie/Modal/AlertModal';
import ConfigAppearance from '../../dummie/Appearance/ConfigAppearance';
import {useConfigurationFacade} from '../../../hooks/facade/configurationFacade.hook';
import {ConfigurationState} from '../../../stores/configuration.store';
import Category from './Category';

export const changeFavicon = (favicon: string): void => {
  const fav = document.getElementById('favicon_page') as HTMLLinkElement;
  if (fav) fav.href = favicon;
};

export const capitalize = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const Settings: React.FC<RouteComponentProps> = ({history}) => {
  const [checked] = useState(true);
  const [{state: {usrPrf, active}, listUsers, update}] = useUserProfileFacade();
  const [userList, setUserList] = useState<UserProfileType[]>();
  const [displayAppearanceStatus, setDisplayAppearanceStatus] = useState(false);
  const [{logo, favicon, idDomain, saveDomain, updateDomain}] = useConfigurationFacade();
  const [displayCategoryConfig, setDisplayCategoryConfig] = useState<boolean>(false);

  // eslint-disable-next-line
  useEffect(() => {
    if (JSON.stringify(userList) !== JSON.stringify(usrPrf)) {
      setUserList(usrPrf);
    }
  });

  useEffect(()=> {
    if (history.location.pathname === '/settings/') {
      listUsers();
    }
    // eslint-disable-next-line
  }, [history.location.pathname]);

  useEffect(() => {
    changeFavicon(favicon);
  }, [favicon]);

  const updateUser = (u: UserProfileType): void => {
    if (u.id === active?.id) setObject({id: u.id, name: u.name, role: u.role}, PROFILE);
    update(u);
  };

  const saveConfigPost = (config: ConfigurationState): void => {
    if (!idDomain) {
      saveDomain(config);
    } else {
      updateDomain({...config, id: idDomain});
    }
  };

  return (
    <>
      <IonPage >
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" sizeSm="12" offsetSm="0">
                <IonSegment className={ANIMATION_BOUNCE_IN_DOWN_RIGHT}>
                  <IonSegmentButton checked={checked}>
                    <IonLabel>Configuracion</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
                <IonRow>
                  <IonCol sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0">
                    <UserList updateRole={(user: UserProfileType): void => updateUser(user)} userList={usrPrf}/>
                  </IonCol>
                  <IonCol sizeMd="7" offsetMd="2" sizeSm="12" offsetSm="0" sizeXs="12" offsetXs="0">
                    <h1 className={`socialMediaTitle ${ANIMATION_SLIDE_IN_LEFT}`}>Conectar Con Mis Redes Sociales</h1>
                    <ConnectAccount className={ANIMATION_SLIDE_IN_RIGHT}/>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton color="light">
              <IonIcon icon={settings} />
            </IonFabButton>
            <IonFabList side="top">
              <IonFabButton color="primary" onClick={(): void => setDisplayAppearanceStatus(true)}>
                <IonIcon icon={easel}/>
              </IonFabButton>
              <IonFabButton color="primary" onClick={(): void => setDisplayCategoryConfig(true)}>
                <IonIcon icon={bookmark} />
              </IonFabButton>
            </IonFabList>
          </IonFab>
          <AlertModal
            isOpen={displayAppearanceStatus}
            dismiss={(): void => setDisplayAppearanceStatus(false)}
            title="Configurar Apariencia"
          >
            <ConfigAppearance config={{favicon, logo}} updateConfig={saveConfigPost}/>
          </AlertModal>
          <AlertModal
            isOpen={displayCategoryConfig}
            dismiss={(): void => setDisplayCategoryConfig(false)}
            title="Category Config">
            <Category />
          </AlertModal>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Settings;
