/* eslint-disable @typescript-eslint/explicit-function-return-type,no-console */

import React, {useEffect} from 'react';
import {setObject} from '../../shared/Storage';
import {TOKEN} from '../../shared/ConstantDataStructure';
import {whatever} from '../../shared/customType';

interface Props {
  setLogin: Function;
}

const Login: React.FC<Props> = ({setLogin}) => {
  const recivedMessage = (event: whatever) => {
    let {token} = event.data.map ? event.data.map: event.data;
    token = token && token.map ? token.map: token;
    if (token) {
      setObject(token, TOKEN);
      setLogin(token);
    }
  };
  // eslint-disable-next-line
  useEffect(() => window.addEventListener('message', recivedMessage, false), []);
  return (
    <iframe
      id="accountFrame"
      style={{width: '100%', height: '100%', border: 'none'}}
      src={`${process.env.REACT_APP_CMS_ACCOUNTS_URL}&domain=${window.location.hostname}&at=${2}`}
      title="accounts"
    />
  );
};

export default Login;
