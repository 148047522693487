import React from 'react';
import {SocialMediaType} from '../../../../shared/types/socialMedia.type';
import LabelMedia from '../LabelMedia/LabelMedia';
import {IonIcon, IonLabel} from '@ionic/react';
import {exit} from 'ionicons/icons';

const FacebookLabel: React.FC<{socialMedia: SocialMediaType;onLogOut: Function}> = ({socialMedia, onLogOut}) => {
  return (
    <>
      <LabelMedia
        chanel={socialMedia && socialMedia.nameResource ?
            socialMedia.nameResource :socialMedia && socialMedia.typeResource ?
                socialMedia.typeResource : ''}
        userName={socialMedia && socialMedia.userName ? socialMedia.userName : ''}/>
      <IonLabel color="danger" style={{textAlign: 'right'}}>
        <IonIcon size="small" icon={exit} onClick={(): void => onLogOut(socialMedia.id)} />
      </IonLabel>
    </>
  );
};

export default FacebookLabel;
