import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCol, IonIcon, IonLabel, IonRow,
  IonSkeletonText,
  IonThumbnail,
} from '@ionic/react';
import {RouteComponentProps, withRouter} from 'react-router';
import Img from 'react-image';
import Like from '../Like/Like';
import {logoYoutube} from 'ionicons/icons';
import {URL_IMAGE_SMALL} from '../../../shared/ConstantUrl';


interface Props extends RouteComponentProps{
    summary?: string;
    title?: string;
    author: string;
    picture?: string;
    video?: string;
    likes?: number;
}

const PostDisplay: React.FC<Props> = ({title, picture, author, summary, video, likes}) => {
  return (
    <IonCard>
      <div>
        <Img
          alt={title}
          src={picture && !video ? `${URL_IMAGE_SMALL}${picture}`:
              video ? picture :
                  'assets/image/noImage.jpg'}
          loader={ <IonThumbnail style={{width: '100%', height: '200px'}} slot="start">
            <IonSkeletonText animated/>
          </IonThumbnail>}
        />
        {video ?
              <div className="splashMediaIcon" style={{height: '70%'}}>
                <IonIcon icon={logoYoutube} color="danger"/>
              </div> : null}
      </div>
      <IonCardHeader>
        <IonCardSubtitle>
          <IonRow>
            <IonCol style={{display: 'flex', paddingLeft: '0px'}}>
              <IonLabel>
                <h3>{author.substring(0, 25)}{author.length > 25 ? '...' : ''}</h3>
              </IonLabel>
            </IonCol>
            <IonCol style={{display: 'flex', paddingTop: '0px'}} size="3">
              <Like likeCount={likes as number} disable/>
            </IonCol>
          </IonRow>
        </IonCardSubtitle>
        <IonCardTitle style={{marginTop: '0px'}}>{title}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {summary}
      </IonCardContent>
    </IonCard>
  );
};

export default withRouter(PostDisplay);
