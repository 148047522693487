import {QueryEntity} from '@datorama/akita';
import {SocialMediaState, socialMediaStore, SocialMediaStore} from '../stores/socialMedia.store';
import {SocialMediaModel} from '../models/socialMedia.model';

export class SocialMediaQuery extends QueryEntity<SocialMediaState, SocialMediaModel> {
  constructor(private socialMediaStore: SocialMediaStore) {
    super(socialMediaStore);
  }

  public socialMedias$ = this.selectAll();
}

export const socialMediaQuery = new SocialMediaQuery(socialMediaStore);
