import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {whatever} from '../../../../shared/customType';
import {logoFacebook} from 'ionicons/icons';
import {IonIcon} from '@ionic/react';

interface Props {
    onFacebookUserInfo: Function;
    status: boolean;
}

const FacebookMedia: React.FC<Props> = ({onFacebookUserInfo, status}) => {
  return (
    <FacebookLogin
      appId="716616005511906"
      callback={onFacebookUserInfo}
      scope="manage_pages,pages_show_list,publish_pages,public_profile"
      textButton="Connect with Facebook"
      version="5.0"
      size="small"
      render={(renderProps: whatever): React.ReactNode => (
        <IonIcon
          onClick={status ? renderProps.onClick : (): void => {}}
          className="icon-social-media"
          color="primary"
          icon={logoFacebook}
          size="large"/>
      )}
    />
  );
};

export default FacebookMedia;
