import {useEffect, useState} from 'react';
import {ResponseError} from '../../shared/types/genericResponse.type';
import {Subscription} from 'rxjs';
import {onEmit} from './postFacade.hook';
import {globalQuery} from '../../query/global.query';
import {userProfileService} from '../../services/userProfile.service';
import {socialMediaService} from '../../services/socialMedia.service';

export function useGlobalFacade(): [
    {errorHttp: ResponseError;renewToken: Function;tokenYoutubeActive: string;
      accessYoutubeToken: Function;hasMorePost: boolean;}
      ] {
  const [errorHttp, setErrorHttp] = useState();
  const [tokenYoutubeActive, setTokenYoutubeActive] = useState();
  const [hasMorePost, setHasMorePost] = useState();

  const renewToken = (): void => userProfileService.renewToken();
  const accessYoutubeToken = (): void => socialMediaService.accessTokenYoutube();

  useEffect(() => {
    const subscription: Subscription[] = [
      onEmit(globalQuery.errorHttp$, (value) => setErrorHttp(value)),
      onEmit(globalQuery.tokenYoutubeActive$, (value) => setTokenYoutubeActive(value)),
      onEmit(globalQuery.hasMorePost$, (value) => setHasMorePost(value)),
    ];
    return (): void =>{
      subscription.map((value) => value.unsubscribe());
    };
  }, []);

  return [{errorHttp, renewToken, tokenYoutubeActive, accessYoutubeToken, hasMorePost}];
}
