import React from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardSubtitle, IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonTitle,
} from '@ionic/react';
import {YoutubeType} from '../../../shared/types/youtube.type';
import '../../../styles/videoCard.scss';
import {doneAll} from 'ionicons/icons';
import {formatDate} from '../../../shared/dateUtil';

interface Props {
  item: YoutubeType;
  selected: Function;
}

const VideoCard: React.FC<Props> = ({item, selected}) => {
  return (
    <IonCard style={{width: '100%'}}>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeMd="2" offsetMd="0" sizeXs="12" offsetSm="0">
              <IonItem lines="none">
                <img width="100" height="100" src={item.displayImage} alt={`pict-${item.videoId}}`} />
              </IonItem>
            </IonCol>
            <IonCol sizeMd="9" offsetMd="0" sizeXs="12" offsetXs="0">
              <IonTitle>{item.title}</IonTitle>
              <IonCardSubtitle >{item.description}</IonCardSubtitle>
              <IonLabel>{formatDate(item.publishedDate as string, 'LLLL', 'es')}</IonLabel>
            </IonCol>
            <IonCol sizeMd="1" offsetMd="0">
              <IonItem className="selectVideoButton" lines="none" button onClick={(): void => selected(item)}>
                <IonIcon icon={doneAll} />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default VideoCard;
