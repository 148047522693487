import React, {useState} from 'react';
import {whatever} from '../../../shared/customType';
import {youtubeService} from '../../../services/youtube.service';
import {YoutubeType} from '../../../shared/types/youtube.type';
import {IonIcon, IonInput, IonItem, IonLabel, IonList, IonGrid, IonRow, IonCol} from '@ionic/react';
import VideoCard from './videoCard';
import {search} from 'ionicons/icons';
import '../../../styles/search.scss';

interface Props {
  selected: Function;
}

const Search: React.FC<Props> = ({selected}) => {
  const [result, setResult] = useState<YoutubeType[]>();
  const [searchType, setSearchTipe] = useState('');

  const handleSearch = (e: whatever): void => {
    setSearchTipe(e.detail.value);
  };

  const executeSearch = (): void => {
    if (searchType.trim() !== '') {
      youtubeService.getVideoList(searchType).subscribe((value) => {
        setResult(value);
      });
    }
  };
  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd="8" offsetMd="1">
            <IonItem>
              <IonLabel position="floating">Buscar en Youtube</IonLabel>
              <IonInput value={searchType} onIonChange={(e: whatever): void => handleSearch(e)} />
            </IonItem>
          </IonCol>
          <IonCol sizeMd="3" offsetMd="0">
            <IonItem
              lines="none"
              style={{width: 'fit-content'}}
              className="iconSearchYoutube"
              button
              onClick={(): void => executeSearch()}>
              <IonIcon icon={search} />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonList style={{overflow: 'auto'}}>
        {result?.map((item: YoutubeType, index: number) => (
          <IonItem lines="none" style={{width: '100%'}} key={index}>
            <VideoCard selected={(e: YoutubeType): void => selected(e)} item={item}/>
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default Search;
