
import {whatever} from '../shared/customType';
import {from, Observable} from 'rxjs';
import {API_KEY} from '../shared/ConstantDataStructure';
import {catchError, map} from 'rxjs/operators';
import {YoutubeType} from '../shared/types/youtube.type';

export class YoutubeService {
  searchYoutube = require('youtube-api-v3-search');

  getVideoList(searchValue: string): Observable<whatever> {
    return from(this.searchYoutube(API_KEY, {q: searchValue, part: 'snippet', type: 'video'})).pipe(
        map((value: whatever) => {
          const aux: YoutubeType[] = [];
          value.items.map((item: whatever) => {
            // console.log('item youtube complete: ', item);
            return aux.push(
                {
                  channelTittle: item.snippet.channelTitle,
                  description: item.snippet.description,
                  displayImage: item.snippet.thumbnails.medium.url,
                  publishedDate: item.snippet.publishedAt,
                  title: item.snippet.title,
                  videoId: item.id.videoId,
                  selected: false,
                });
          });
          return aux;
        }),
        catchError((err: whatever) => {
          return err;
        }),
    );
  }
}

export const youtubeService = new YoutubeService();
