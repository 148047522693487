import React from 'react';

const Profle: React.FC = () => {
  return (
    <iframe
      allow="camera;"
      style={{width: '100%', height: '100%', border: 'none'}}
      src={`${process.env.REACT_APP_CMS_ACCOUNTS_URL}user`}
      title="accounts"
    />
  );
};

export default Profle;
